
.navbar-reiss {
  background: $white;
  color: $brand-primary;
  // font-weight: bold;
  font-size: 16px;
  min-height: 38px;
  margin: 0;
  border: none;
  border-bottom: 1px solid $brand-primary;

  @media(min-width: $screen-sm-min) {
    font-size: 18px;
    min-height: 60px;
  }

  @media(min-width: $screen-md-min) {
    min-height: 40px;
  }

  @media(min-width: $screen-lg-min) {
    font-size: 21px;
    line-height: 43px;
    min-height: 50px;
  }

  @media(min-width: $screen-lx-min) {
    font-size: 24.5px;
    // line-height: 43px;
    // min-height: 50px;
  }

  .navbar-collapse {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-toggle {
    margin: 0;
  }

  &.navbar-inverse {
    background: $brand-primary;

    .nav-dots > li > a {
      color: $white;

    }

    .navbar-toggle {
      background: $white;
      border: none;
    }

    .nav-dots > li > a:hover {
      border-bottom: 1px solid $white;
    }
  }

  @media(min-width: $screen-sm-min) {
    &.navbar-inverse {
      background: transparent;
      margin-bottom: 1%;
      border-bottom: none;
    }
  }
}

.navbar-brand {
  color: $brand-primary;
  font-weight: bold;
  height: 38px;
  line-height: 16px;

  &:focus {
    color: darken($brand-primary, 20%);
  }
}

.bordered-nav.navbar-reiss {
  border-bottom: 1px solid $brand-primary;
}

.nav-dots {

  > li {
    text-align: center;
    text-transform: uppercase;

    > a {
      color: $brand-primary;
      // font-family: "Muli";
      padding: 0;

      &:hover,
      &.active,
      &:focus {
        background: none;
      }


      @media(max-width: $screen-sm-min) {
        border-bottom: 1px solid lighten($brand-primary, 3%);
        height: 30px;
        line-height: 30px;
        margin-bottom: 0;
      }
    }

    &.active a {
      @media (max-width: $screen-sm-min) {
        font-weight: bold;
      }

      @media (min-width: $screen-sm-min) and (max-width: $screen-lg-min) {
        border-bottom: 1px solid $brand-primary;
      }
    }
  }

}

$nav-arrow-width: 18px;

@media(min-width: $screen-lg-min) {
  .nav-arrow {
    background: $brand-primary;
    border-radius: $nav-arrow-width;
    bottom: -$nav-arrow-width + 2px;
    height: $nav-arrow-width;
    left: 50%;
    margin-left: -$nav-arrow-width / 2;
    position: absolute;
    width: $nav-arrow-width;
  }
}
