
.header {
}

.logo-subtext {
  text-transform: uppercase;
  font-size: 17px;
  padding-right: 40px;

  @media(min-width: $screen-sm-min) {
    font-size: 24.5px;
    padding-right: 50px;
  }
}

.logo-img {
  width: 80%;

  @media(min-width: $screen-sm-min) {
    width: 20%;
  }
}

.page-header {
  border: none;
}
.hero {
  background: $white; // $brand-primary;
  background-size: cover;
  min-height: 40px;

  &.home {
    background: $white;
    position: relative;
    min-height: 600px;

    .hero-border {
      mix-blend-mode: multiply;
    }

    @media(min-width: $screen-md-min) {
      min-height: 850px;
    }
  }
}

.logo-pos {
  text-align: right;
  padding-right: 20px;
  // img {
  //   width: 100%;
  // }
}

@media(max-width: $screen-md-max) {
  .hero {
    display: none;
    // background-size: auto 100%;

    &.home, &.carousel {
      display: block;
    }

  }
}

.carousel {
  height: 200px;
  margin-top: 25px;

  @media(min-width: $screen-md-min) {
    height: 400px;
  }

  @media(min-width: $screen-lg-min) {
    height: 500px;
  }
}

.hero-border {
  background: $brand-primary;

  position: absolute;
  min-height: 12.5%;
  top: 0;
  width: 100%;

  @media(min-width: $screen-sm-min) {
    min-height: 8%;
  }
}

.hero-bottom {
  position: absolute;
  top: auto;
  bottom: 0;
  min-height: 15%;
  width: 100%;

  @media(min-width: $screen-sm-min) {
    min-height: 10%;
  }

  .hero-border {
    height: 15%;
    position: fixed;
    top: initial;
  }
}

.actionicon-container {
  position: absolute;
  width: 100%;
  top: 20px;
  height: 10vw;
  max-height: 12vh;

  @media(max-width: $screen-xs-max) {
    &.bottom {
      top: initial;
      left: 0px;
      bottom: 0px;
    }
  }

  @media(min-width: $screen-sm-min) {
    top: 40px;

    &.bottom {
      top: initial;
      bottom: 80px;
      height: 5vw;
      max-height: 10vh;
    }
  }

  @media(min-width: $screen-md-min) {
    top: 23px;
  }

  @media(min-width: $screen-lg-min) {
    top: 20px;
  }
}

.actionicons {

  margin: 0 auto;
  text-align: center;
  width: 100%;
  height: 200%;
  display: flex;
  justify-content: space-around;

  &.left {
    justify-content: flex-start;
  }

  @media(max-width: $screen-xs-max) {
    // display: block;

    &.left {
      text-align: left;
    }

    img {
      width: 100%;
      margin: 2% 0;
      padding: 0 5px;
      &:first-child {
        padding-left: 10px;
      }

      &:last-child {
        padding-right: 10px;
      }
    }
  }

  @media(min-width: $screen-sm-min) {
    width: 90%;
    img {
      // margin: 0 4%;
      height: 90%;
      width: auto;
    }
  }

  @media(min-width: $screen-md-min) {
    width: 85%;

    img {
      height: 80%;
      width: auto;
    }
  }

  @media(min-width: $screen-lg-min) {
    width: 83%;

    img {
      height: 125%;
      width: auto;
    }
  }

  @media(min-width: $screen-lx-min) {
    width: 80%;

    img {
      height: 150%;
      width: auto;
    }
  }

}
