.pswp {
  width: 100%;
  height: 100%;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 1500;
  -webkit-text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  outline: none;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.pswp * {
  box-sizing: border-box;
}

.pswp img {
  max-width: none;
}

.pswp--animate_opacity {
  opacity: .001;
  will-change: opacity;
  transition: opacity .333s cubic-bezier(.4, 0, .22, 1);
}

.pswp--open {
  display: block;
}

.pswp--zoom-allowed .pswp__img {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.pswp--zoomed-in .pswp__img {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.pswp--dragging .pswp__img {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.pswp__bg {
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateZ(0);
}

.pswp__scroll-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.pswp__container, .pswp__zoom-wrap {
  -ms-touch-action: none;
  touch-action: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.pswp__container, .pswp__img {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.pswp__zoom-wrap {
  width: 100%;
  transform-origin: 0 0;
  -webkit-transition: -webkit-transform .333s cubic-bezier(.4, 0, .22, 1);
  transition: transform .333s cubic-bezier(.4, 0, .22, 1);
  position: absolute;
}

.pswp__bg {
  will-change: opacity;
  transition: opacity .333s cubic-bezier(.4, 0, .22, 1);
}

.pswp--animated-in .pswp__bg, .pswp--animated-in .pswp__zoom-wrap {
  transition: none;
}

.pswp__container, .pswp__zoom-wrap {
  -webkit-backface-visibility: hidden;
}

.pswp__item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.pswp__img {
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__img--placeholder {
  -webkit-backface-visibility: hidden;
}

.pswp__img--placeholder--blank {
  background: #222;
}

.pswp--ie .pswp__img {
  top: 0;
  left: 0;
  width: 100% !important;
  height: auto !important;
}

.pswp__error-msg {
  width: 100%;
  text-align: center;
  color: #ccc;
  margin-top: -8px;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 50%;
  left: 0;
}

.pswp__error-msg a {
  color: #ccc;
  text-decoration: underline;
}

.pswp__button {
  width: 44px;
  height: 44px;
  cursor: pointer;
  -webkit-appearance: none;
  float: right;
  opacity: .75;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  transition: opacity .2s;
  display: block;
  position: relative;
  overflow: visible;
}

.pswp__button:focus, .pswp__button:hover {
  opacity: 1;
}

.pswp__button:active {
  opacity: .9;
  outline: none;
}

.pswp__button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.pswp__ui--over-close .pswp__button--close {
  opacity: 1;
}

.pswp__button, .pswp__button--arrow--left:before, .pswp__button--arrow--right:before {
  width: 44px;
  height: 44px;
  background: url("default-skin.1a8c5431.png") 0 0 / 264px 88px no-repeat;
}

@media (-webkit-min-device-pixel-ratio: 1.1), (-webkit-min-device-pixel-ratio: 1.09375), (min-resolution: 105dpi), (min-resolution: 1.1dppx) {
  .pswp--svg .pswp__button, .pswp--svg .pswp__button--arrow--left:before, .pswp--svg .pswp__button--arrow--right:before {
    background-image: url("default-skin.dca027d6.svg");
  }

  .pswp--svg .pswp__button--arrow--left, .pswp--svg .pswp__button--arrow--right {
    background: none;
  }
}

.pswp__button--close {
  background-position: 0 -44px;
}

.pswp__button--share {
  background-position: -44px -44px;
}

.pswp__button--fs {
  display: none;
}

.pswp--supports-fs .pswp__button--fs {
  display: block;
}

.pswp--fs .pswp__button--fs {
  background-position: -44px 0;
}

.pswp__button--zoom {
  background-position: -88px 0;
  display: none;
}

.pswp--zoom-allowed .pswp__button--zoom {
  display: block;
}

.pswp--zoomed-in .pswp__button--zoom {
  background-position: -132px 0;
}

.pswp--touch .pswp__button--arrow--left, .pswp--touch .pswp__button--arrow--right {
  visibility: hidden;
}

.pswp__button--arrow--left, .pswp__button--arrow--right {
  width: 70px;
  height: 100px;
  background: none;
  margin-top: -50px;
  position: absolute;
  top: 50%;
}

.pswp__button--arrow--left {
  left: 0;
}

.pswp__button--arrow--right {
  right: 0;
}

.pswp__button--arrow--left:before, .pswp__button--arrow--right:before {
  content: "";
  height: 30px;
  width: 32px;
  background-color: rgba(0, 0, 0, .3);
  position: absolute;
  top: 35px;
}

.pswp__button--arrow--left:before {
  background-position: -138px -44px;
  left: 6px;
}

.pswp__button--arrow--right:before {
  background-position: -94px -44px;
  right: 6px;
}

.pswp__counter, .pswp__share-modal {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pswp__share-modal {
  width: 100%;
  height: 100%;
  z-index: 1600;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  background: rgba(0, 0, 0, .5);
  padding: 10px;
  transition: opacity .25s ease-out;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__share-modal--hidden {
  display: none;
}

.pswp__share-tooltip {
  z-index: 1620;
  width: auto;
  -webkit-transition: -webkit-transform .25s;
  -webkit-backface-visibility: hidden;
  will-change: transform;
  background: #fff;
  border-radius: 2px;
  transition: transform .25s;
  display: block;
  position: absolute;
  top: 56px;
  right: 44px;
  transform: translateY(6px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, .25);
}

.pswp__share-tooltip a {
  color: #000;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

.pswp__share-tooltip a:hover {
  color: #000;
  text-decoration: none;
}

.pswp__share-tooltip a:first-child {
  border-radius: 2px 2px 0 0;
}

.pswp__share-tooltip a:last-child {
  border-radius: 0 0 2px 2px;
}

.pswp__share-modal--fade-in {
  opacity: 1;
}

.pswp__share-modal--fade-in .pswp__share-tooltip {
  transform: translateY(0);
}

.pswp--touch .pswp__share-tooltip a {
  padding: 16px 12px;
}

a.pswp__share--facebook:before {
  content: "";
  width: 0;
  height: 0;
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  pointer-events: none;
  border: 6px solid rgba(0, 0, 0, 0);
  border-bottom-color: #fff;
  display: block;
  position: absolute;
  top: -12px;
  right: 15px;
}

a.pswp__share--facebook:hover {
  color: #fff;
  background: #3e5c9a;
}

a.pswp__share--facebook:hover:before {
  border-bottom-color: #3e5c9a;
}

a.pswp__share--twitter:hover {
  color: #fff;
  background: #55acee;
}

a.pswp__share--pinterest:hover {
  color: #ce272d;
  background: #ccc;
}

a.pswp__share--download:hover {
  background: #ddd;
}

.pswp__counter {
  height: 44px;
  color: #fff;
  opacity: .75;
  padding: 0 10px;
  font-size: 13px;
  line-height: 44px;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__caption {
  width: 100%;
  min-height: 44px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pswp__caption small {
  color: #bbb;
  font-size: 11px;
}

.pswp__caption__center {
  text-align: left;
  max-width: 420px;
  color: #ccc;
  margin: 0 auto;
  padding: 10px;
  font-size: 13px;
  line-height: 20px;
}

.pswp__caption--empty {
  display: none;
}

.pswp__caption--fake {
  visibility: hidden;
}

.pswp__preloader {
  width: 44px;
  height: 44px;
  opacity: 0;
  will-change: opacity;
  direction: ltr;
  margin-left: -22px;
  transition: opacity .25s ease-out;
  position: absolute;
  top: 0;
  left: 50%;
}

.pswp__preloader__icn {
  width: 20px;
  height: 20px;
  margin: 12px;
}

.pswp__preloader--active {
  opacity: 1;
}

.pswp__preloader--active .pswp__preloader__icn {
  background: url("preloader.9ad95bd8.gif") no-repeat;
}

.pswp--css_animation .pswp__preloader--active {
  opacity: 1;
}

.pswp--css_animation .pswp__preloader--active .pswp__preloader__icn {
  animation: clockwise .5s linear infinite;
}

.pswp--css_animation .pswp__preloader--active .pswp__preloader__donut {
  animation: donut-rotate 1s cubic-bezier(.4, 0, .22, 1) infinite;
}

.pswp--css_animation .pswp__preloader__icn {
  opacity: .75;
  width: 14px;
  height: 14px;
  background: none;
  margin: 0;
  position: absolute;
  top: 15px;
  left: 15px;
}

.pswp--css_animation .pswp__preloader__cut {
  width: 7px;
  height: 14px;
  position: relative;
  overflow: hidden;
}

.pswp--css_animation .pswp__preloader__donut {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  background: none;
  border: 2px solid #fff;
  border-color: #fff #fff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  border-radius: 50%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1024px) {
  .pswp__preloader {
    float: right;
    margin: 0;
    position: relative;
    top: auto;
    left: auto;
  }
}

@keyframes clockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes donut-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    transform: rotate(-140deg);
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

.pswp__ui {
  -webkit-font-smoothing: auto;
  visibility: visible;
  opacity: 1;
  z-index: 1550;
}

.pswp__top-bar {
  height: 44px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__caption, .pswp__top-bar, .pswp--has_mouse .pswp__button--arrow--left, .pswp--has_mouse .pswp__button--arrow--right {
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  transition: opacity .333s cubic-bezier(.4, 0, .22, 1);
}

.pswp--has_mouse .pswp__button--arrow--left, .pswp--has_mouse .pswp__button--arrow--right {
  visibility: visible;
}

.pswp__top-bar, .pswp__caption {
  background-color: rgba(0, 0, 0, .5);
}

.pswp__ui--fit .pswp__top-bar, .pswp__ui--fit .pswp__caption {
  background-color: rgba(0, 0, 0, .3);
}

.pswp__ui--idle .pswp__top-bar, .pswp__ui--idle .pswp__button--arrow--left, .pswp__ui--idle .pswp__button--arrow--right {
  opacity: 0;
}

.pswp__ui--hidden .pswp__top-bar, .pswp__ui--hidden .pswp__caption, .pswp__ui--hidden .pswp__button--arrow--left, .pswp__ui--hidden .pswp__button--arrow--right {
  opacity: .001;
}

.pswp__ui--one-slide .pswp__button--arrow--left, .pswp__ui--one-slide .pswp__button--arrow--right, .pswp__ui--one-slide .pswp__counter {
  display: none;
}

.pswp__element--disabled {
  display: none !important;
}

.pswp--minimal--dark .pswp__top-bar {
  background: none;
}

.fa, .fas, .far, .fal, .fad, .fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
  display: inline-block;
}

.fa-lg {
  vertical-align: -.0667em;
  font-size: 1.33333em;
  line-height: .75em;
}

.fa-xs {
  font-size: .75em;
}

.fa-sm {
  font-size: .875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: 2.5em;
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  text-align: center;
  width: 2em;
  line-height: inherit;
  position: absolute;
  left: -2em;
}

.fa-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left, .fas.fa-pull-left, .far.fa-pull-left, .fal.fa-pull-left, .fab.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right, .fas.fa-pull-right, .far.fa-pull-right, .fal.fa-pull-right, .fab.fa-pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: fa-spin 2s linear infinite;
}

.fa-pulse {
  animation: fa-spin 1s steps(8, end) infinite;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1);
}

:root .fa-rotate-90, :root .fa-rotate-180, :root .fa-rotate-270, :root .fa-flip-horizontal, :root .fa-flip-vertical, :root .fa-flip-both {
  filter: none;
}

.fa-stack {
  height: 2em;
  vertical-align: middle;
  width: 2.5em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-500px:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-acquisitions-incorporated:before {
  content: "";
}

.fa-ad:before {
  content: "";
}

.fa-address-book:before {
  content: "";
}

.fa-address-card:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-air-freshener:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-allergies:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-american-sign-language-interpreting:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angry:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-apple-alt:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-arrow-alt-circle-down:before {
  content: "";
}

.fa-arrow-alt-circle-left:before {
  content: "";
}

.fa-arrow-alt-circle-right:before {
  content: "";
}

.fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-arrows-alt-h:before {
  content: "";
}

.fa-arrows-alt-v:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-asterisk:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-at:before {
  content: "";
}

.fa-atlas:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-baby:before {
  content: "";
}

.fa-baby-carriage:before {
  content: "";
}

.fa-backspace:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-bacteria:before {
  content: "";
}

.fa-bacterium:before {
  content: "";
}

.fa-bahai:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-balance-scale-left:before {
  content: "";
}

.fa-balance-scale-right:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-band-aid:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-bars:before {
  content: "";
}

.fa-baseball-ball:before {
  content: "";
}

.fa-basketball-ball:before {
  content: "";
}

.fa-bath:before {
  content: "";
}

.fa-battery-empty:before {
  content: "";
}

.fa-battery-full:before {
  content: "";
}

.fa-battery-half:before {
  content: "";
}

.fa-battery-quarter:before {
  content: "";
}

.fa-battery-three-quarters:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-behance-square:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-bible:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-biking:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-blender:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-bolt:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-book-dead:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-reader:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-border-style:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-box-tissue:before {
  content: "";
}

.fa-boxes:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-broadcast-tower:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-burn:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-bus-alt:before {
  content: "";
}

.fa-business-time:before {
  content: "";
}

.fa-buy-n-large:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-calendar-alt:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-calendar-times:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-camera:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-car:before {
  content: "";
}

.fa-car-alt:before {
  content: "";
}

.fa-car-battery:before {
  content: "";
}

.fa-car-crash:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-caravan:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-caret-square-down:before {
  content: "";
}

.fa-caret-square-left:before {
  content: "";
}

.fa-caret-square-right:before {
  content: "";
}

.fa-caret-square-up:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-carrot:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-chalkboard:before {
  content: "";
}

.fa-chalkboard-teacher:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-chart-area:before {
  content: "";
}

.fa-chart-bar:before {
  content: "";
}

.fa-chart-line:before {
  content: "";
}

.fa-chart-pie:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-clinic-medical:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-clock:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-cloud-download-alt:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-cloud-upload-alt:before {
  content: "";
}

.fa-cloudflare:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-cocktail:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-cog:before {
  content: "";
}

.fa-cogs:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-comment-alt:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-comment-dots:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-compress-alt:before {
  content: "";
}

.fa-compress-arrows-alt:before {
  content: "";
}

.fa-concierge-bell:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-cotton-bureau:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-credit-card:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-crop-alt:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-cut:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-dailymotion:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-deaf:before {
  content: "";
}

.fa-deezer:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-desktop:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-diagnoses:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-digital-tachograph:before {
  content: "";
}

.fa-directions:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-divide:before {
  content: "";
}

.fa-dizzy:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-dollar-sign:before {
  content: "";
}

.fa-dolly:before {
  content: "";
}

.fa-dolly-flatbed:before {
  content: "";
}

.fa-donate:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-dot-circle:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-drafting-compass:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-dribbble-square:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-edge-legacy:before {
  content: "";
}

.fa-edit:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-equals:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-euro-sign:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-exchange-alt:before {
  content: "";
}

.fa-exclamation:before {
  content: "";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-exclamation-triangle:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-expand-alt:before {
  content: "";
}

.fa-expand-arrows-alt:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-external-link-alt:before {
  content: "";
}

.fa-external-link-square-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-dropper:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-facebook-square:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-faucet:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-feather-alt:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-alt:before {
  content: "";
}

.fa-file-archive:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-file-download:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-export:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-file-import:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-file-medical:before {
  content: "";
}

.fa-file-medical-alt:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-file-signature:before {
  content: "";
}

.fa-file-upload:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-fill-drip:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-fire-alt:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-firefox-browser:before {
  content: "";
}

.fa-first-aid:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-fist-raised:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-flushed:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-font-awesome:before {
  content: "";
}

.fa-font-awesome-alt:before {
  content: "";
}

.fa-font-awesome-flag:before {
  content: "";
}

.fa-font-awesome-logo-full:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-football-ball:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-frown:before {
  content: "";
}

.fa-frown-open:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-funnel-dollar:before {
  content: "";
}

.fa-futbol:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-gavel:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-git-square:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-github-square:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-glass-cheers:before {
  content: "";
}

.fa-glass-martini:before {
  content: "";
}

.fa-glass-martini-alt:before {
  content: "";
}

.fa-glass-whiskey:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-globe-africa:before {
  content: "";
}

.fa-globe-americas:before {
  content: "";
}

.fa-globe-asia:before {
  content: "";
}

.fa-globe-europe:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-golf-ball:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-google-pay:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-google-plus-square:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-graduation-cap:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-greater-than:before {
  content: "";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-grimace:before {
  content: "";
}

.fa-grin:before {
  content: "";
}

.fa-grin-alt:before {
  content: "";
}

.fa-grin-beam:before {
  content: "";
}

.fa-grin-beam-sweat:before {
  content: "";
}

.fa-grin-hearts:before {
  content: "";
}

.fa-grin-squint:before {
  content: "";
}

.fa-grin-squint-tears:before {
  content: "";
}

.fa-grin-stars:before {
  content: "";
}

.fa-grin-tears:before {
  content: "";
}

.fa-grin-tongue:before {
  content: "";
}

.fa-grin-tongue-squint:before {
  content: "";
}

.fa-grin-tongue-wink:before {
  content: "";
}

.fa-grin-wink:before {
  content: "";
}

.fa-grip-horizontal:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-guilded:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-h-square:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-hacker-news-square:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-hamburger:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-hand-holding-medical:before {
  content: "";
}

.fa-hand-holding-usd:before {
  content: "";
}

.fa-hand-holding-water:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-hand-paper:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-hand-rock:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hand-sparkles:before {
  content: "";
}

.fa-hand-spock:before {
  content: "";
}

.fa-hands:before {
  content: "";
}

.fa-hands-helping:before {
  content: "";
}

.fa-hands-wash:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-handshake-alt-slash:before {
  content: "";
}

.fa-handshake-slash:before {
  content: "";
}

.fa-hanukiah:before {
  content: "";
}

.fa-hard-hat:before {
  content: "";
}

.fa-hashtag:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-hdd:before {
  content: "";
}

.fa-head-side-cough:before {
  content: "";
}

.fa-head-side-cough-slash:before {
  content: "";
}

.fa-head-side-mask:before {
  content: "";
}

.fa-head-side-virus:before {
  content: "";
}

.fa-heading:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-headphones-alt:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-heart-broken:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-hiking:before {
  content: "";
}

.fa-hippo:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-hive:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-home:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-hospital:before {
  content: "";
}

.fa-hospital-alt:before {
  content: "";
}

.fa-hospital-symbol:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-hot-tub:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hourglass-end:before {
  content: "";
}

.fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-start:before {
  content: "";
}

.fa-house-damage:before {
  content: "";
}

.fa-house-user:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-hryvnia:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-icons:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-id-card:before {
  content: "";
}

.fa-id-card-alt:before {
  content: "";
}

.fa-ideal:before {
  content: "";
}

.fa-igloo:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-innosoft:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-instagram-square:before {
  content: "";
}

.fa-instalod:before {
  content: "";
}

.fa-intercom:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-jedi:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-joint:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-journal-whills:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-js-square:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-kaaba:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-kickstarter:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-kiss:before {
  content: "";
}

.fa-kiss-beam:before {
  content: "";
}

.fa-kiss-wink-heart:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-laptop-house:before {
  content: "";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-lastfm-square:before {
  content: "";
}

.fa-laugh:before {
  content: "";
}

.fa-laugh-beam:before {
  content: "";
}

.fa-laugh-squint:before {
  content: "";
}

.fa-laugh-wink:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-less-than:before {
  content: "";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-level-down-alt:before {
  content: "";
}

.fa-level-up-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-link:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-list-ol:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-long-arrow-alt-down:before {
  content: "";
}

.fa-long-arrow-alt-left:before {
  content: "";
}

.fa-long-arrow-alt-right:before {
  content: "";
}

.fa-long-arrow-alt-up:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-luggage-cart:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-lungs-virus:before {
  content: "";
}

.fa-lyft:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-mail-bulk:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-map-marked:before {
  content: "";
}

.fa-map-marked-alt:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-map-marker-alt:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-h:before {
  content: "";
}

.fa-mars-stroke-v:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-mdb:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-medium:before {
  content: "";
}

.fa-medium-m:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-meh:before {
  content: "";
}

.fa-meh-blank:before {
  content: "";
}

.fa-meh-rolling-eyes:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-microblog:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-alt:before {
  content: "";
}

.fa-microphone-alt-slash:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-mixer:before {
  content: "";
}

.fa-mizuni:before {
  content: "";
}

.fa-mobile:before {
  content: "";
}

.fa-mobile-alt:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-monero:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-money-bill-alt:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-money-bill-wave-alt:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-money-check-alt:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-mouse:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-notes-medical:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-octopus-deploy:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-odnoklassniki-square:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-orcid:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-outdent:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-parking:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-pastafarianism:before {
  content: "";
}

.fa-paste:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-pen-alt:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-pen-square:before {
  content: "";
}

.fa-pencil-alt:before {
  content: "";
}

.fa-pencil-ruler:before {
  content: "";
}

.fa-penny-arcade:before {
  content: "";
}

.fa-people-arrows:before {
  content: "";
}

.fa-people-carry:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-perbyte:before {
  content: "";
}

.fa-percent:before {
  content: "";
}

.fa-percentage:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-phone-alt:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-phone-square-alt:before {
  content: "";
}

.fa-phone-volume:before {
  content: "";
}

.fa-photo-video:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-pied-piper-square:before {
  content: "";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-pinterest-square:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-plane-slash:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-plus:before {
  content: "";
}

.fa-plus-circle:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-poll:before {
  content: "";
}

.fa-poll-h:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-poo-storm:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-portrait:before {
  content: "";
}

.fa-pound-sign:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-pray:before {
  content: "";
}

.fa-praying-hands:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-prescription-bottle-alt:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-procedures:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-project-diagram:before {
  content: "";
}

.fa-pump-medical:before {
  content: "";
}

.fa-pump-soap:before {
  content: "";
}

.fa-pushed:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-question:before {
  content: "";
}

.fa-question-circle:before {
  content: "";
}

.fa-quidditch:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-quote-left:before {
  content: "";
}

.fa-quote-right:before {
  content: "";
}

.fa-quran:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-radiation-alt:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-reddit-square:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-redo:before {
  content: "";
}

.fa-redo-alt:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-remove-format:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-reply:before {
  content: "";
}

.fa-reply-all:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-rss:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-ruble-sign:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-running:before {
  content: "";
}

.fa-rupee-sign:before {
  content: "";
}

.fa-rust:before {
  content: "";
}

.fa-sad-cry:before {
  content: "";
}

.fa-sad-tear:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-save:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-search-dollar:before {
  content: "";
}

.fa-search-location:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-seedling:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-shapes:before {
  content: "";
}

.fa-share:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-shekel-sign:before {
  content: "";
}

.fa-shield-alt:before {
  content: "";
}

.fa-shield-virus:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-shipping-fast:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-shopify:before {
  content: "";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-shuttle-van:before {
  content: "";
}

.fa-sign:before {
  content: "";
}

.fa-sign-in-alt:before {
  content: "";
}

.fa-sign-language:before {
  content: "";
}

.fa-sign-out-alt:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-sink:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-skating:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-skiing:before {
  content: "";
}

.fa-skiing-nordic:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-slack:before {
  content: "";
}

.fa-slack-hash:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-sliders-h:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-smile:before {
  content: "";
}

.fa-smile-beam:before {
  content: "";
}

.fa-smile-wink:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-smoking-ban:before {
  content: "";
}

.fa-sms:before {
  content: "";
}

.fa-snapchat:before {
  content: "";
}

.fa-snapchat-ghost:before {
  content: "";
}

.fa-snapchat-square:before {
  content: "";
}

.fa-snowboarding:before {
  content: "";
}

.fa-snowflake:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-soap:before {
  content: "";
}

.fa-socks:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-sort:before {
  content: "";
}

.fa-sort-alpha-down:before {
  content: "";
}

.fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-sort-alpha-up:before {
  content: "";
}

.fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-sort-amount-down:before {
  content: "";
}

.fa-sort-amount-down-alt:before {
  content: "";
}

.fa-sort-amount-up:before {
  content: "";
}

.fa-sort-amount-up-alt:before {
  content: "";
}

.fa-sort-down:before {
  content: "";
}

.fa-sort-numeric-down:before {
  content: "";
}

.fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-sort-numeric-up:before {
  content: "";
}

.fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-sort-up:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-spider:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-square-root-alt:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-star-half-alt:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-steam-square:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-stopwatch-20:before {
  content: "";
}

.fa-store:before {
  content: "";
}

.fa-store-alt:before {
  content: "";
}

.fa-store-alt-slash:before {
  content: "";
}

.fa-store-slash:before {
  content: "";
}

.fa-strava:before {
  content: "";
}

.fa-stream:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-surprise:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-swift:before {
  content: "";
}

.fa-swimmer:before {
  content: "";
}

.fa-swimming-pool:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-sync:before {
  content: "";
}

.fa-sync-alt:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-table-tennis:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-tablet-alt:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-tachometer-alt:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-taxi:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-telegram:before {
  content: "";
}

.fa-telegram-plane:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-tenge:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-theater-masks:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-thermometer-empty:before {
  content: "";
}

.fa-thermometer-full:before {
  content: "";
}

.fa-thermometer-half:before {
  content: "";
}

.fa-thermometer-quarter:before {
  content: "";
}

.fa-thermometer-three-quarters:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbtack:before {
  content: "";
}

.fa-ticket-alt:before {
  content: "";
}

.fa-tiktok:before {
  content: "";
}

.fa-times:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-tint-slash:before {
  content: "";
}

.fa-tired:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-toilet-paper-slash:before {
  content: "";
}

.fa-toolbox:before {
  content: "";
}

.fa-tools:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-torah:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-trailer:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-tram:before {
  content: "";
}

.fa-transgender:before {
  content: "";
}

.fa-transgender-alt:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-trash-alt:before {
  content: "";
}

.fa-trash-restore:before {
  content: "";
}

.fa-trash-restore-alt:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-truck-loading:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-truck-moving:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-tshirt:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-tumblr-square:before {
  content: "";
}

.fa-tv:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-twitter-square:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-umbraco:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-uncharted:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-undo:before {
  content: "";
}

.fa-undo-alt:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-unity:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-university:before {
  content: "";
}

.fa-unlink:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-unlock-alt:before {
  content: "";
}

.fa-unsplash:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-user-alt:before {
  content: "";
}

.fa-user-alt-slash:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-user-circle:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-user-cog:before {
  content: "";
}

.fa-user-edit:before {
  content: "";
}

.fa-user-friends:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-users-cog:before {
  content: "";
}

.fa-users-slash:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-utensil-spoon:before {
  content: "";
}

.fa-utensils:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-vest:before {
  content: "";
}

.fa-vest-patches:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-viadeo-square:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-video:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-vimeo-square:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-virus:before {
  content: "";
}

.fa-virus-slash:before {
  content: "";
}

.fa-viruses:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-volleyball-ball:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-mute:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-vote-yea:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-walking:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-watchman-monitoring:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-weight:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-whatsapp-square:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-window-close:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-wine-glass-alt:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-wodu:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-won-sign:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-wpressr:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-x-ray:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-xing-square:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-yen-sign:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-youtube-square:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  width: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-regular-400.530b4f61.eot");
  src: url("fa-regular-400.530b4f61.eot#iefix") format("embedded-opentype"), url("fa-regular-400.5bedcec6.woff2") format("woff2"), url("fa-regular-400.09c79cc5.woff") format("woff"), url("fa-regular-400.963ebff5.ttf") format("truetype"), url("fa-regular-400.a55d9c35.svg#fontawesome") format("svg");
}

.far {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.6b1a037a.eot");
  src: url("fa-solid-900.6b1a037a.eot#iefix") format("embedded-opentype"), url("fa-solid-900.6002b354.woff2") format("woff2"), url("fa-solid-900.80005ece.woff") format("woff"), url("fa-solid-900.67ecc626.ttf") format("truetype"), url("fa-solid-900.45f46c3e.svg#fontawesome") format("svg");
}

.fa, .fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-brands-400.e4f790e7.eot");
  src: url("fa-brands-400.e4f790e7.eot#iefix") format("embedded-opentype"), url("fa-brands-400.0b52c614.woff2") format("woff2"), url("fa-brands-400.a0b02584.woff") format("woff"), url("fa-brands-400.81da6092.ttf") format("truetype"), url("fa-brands-400.068b1299.svg#fontawesome") format("svg");
}

.fab {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

@media print {
  *, :before, :after {
    color: #000 !important;
    text-shadow: none !important;
    box-shadow: none !important;
    background: none !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  .navbar {
    display: none;
  }

  .btn > .caret, .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }

  .label {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered th, .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

@font-face {
  font-family: Glyphicons Halflings;
  src: url("glyphicons-halflings-regular.cca02454.eot");
  src: url("glyphicons-halflings-regular.cca02454.eot#iefix") format("embedded-opentype"), url("glyphicons-halflings-regular.a3cea8f3.woff2") format("woff2"), url("glyphicons-halflings-regular.3a458e4a.woff") format("woff"), url("glyphicons-halflings-regular.1a33c1eb.ttf") format("truetype"), url("glyphicons-halflings-regular.6e7c7fa3.svg#glyphicons_halflingsregular") format("svg");
}

.glyphicon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Glyphicons Halflings;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  position: relative;
  top: 1px;
}

.glyphicon-asterisk:before {
  content: "*";
}

.glyphicon-plus:before {
  content: "+";
}

.glyphicon-euro:before, .glyphicon-eur:before {
  content: "€";
}

.glyphicon-minus:before {
  content: "−";
}

.glyphicon-cloud:before {
  content: "☁";
}

.glyphicon-envelope:before {
  content: "✉";
}

.glyphicon-pencil:before {
  content: "✏";
}

.glyphicon-glass:before {
  content: "";
}

.glyphicon-music:before {
  content: "";
}

.glyphicon-search:before {
  content: "";
}

.glyphicon-heart:before {
  content: "";
}

.glyphicon-star:before {
  content: "";
}

.glyphicon-star-empty:before {
  content: "";
}

.glyphicon-user:before {
  content: "";
}

.glyphicon-film:before {
  content: "";
}

.glyphicon-th-large:before {
  content: "";
}

.glyphicon-th:before {
  content: "";
}

.glyphicon-th-list:before {
  content: "";
}

.glyphicon-ok:before {
  content: "";
}

.glyphicon-remove:before {
  content: "";
}

.glyphicon-zoom-in:before {
  content: "";
}

.glyphicon-zoom-out:before {
  content: "";
}

.glyphicon-off:before {
  content: "";
}

.glyphicon-signal:before {
  content: "";
}

.glyphicon-cog:before {
  content: "";
}

.glyphicon-trash:before {
  content: "";
}

.glyphicon-home:before {
  content: "";
}

.glyphicon-file:before {
  content: "";
}

.glyphicon-time:before {
  content: "";
}

.glyphicon-road:before {
  content: "";
}

.glyphicon-download-alt:before {
  content: "";
}

.glyphicon-download:before {
  content: "";
}

.glyphicon-upload:before {
  content: "";
}

.glyphicon-inbox:before {
  content: "";
}

.glyphicon-play-circle:before {
  content: "";
}

.glyphicon-repeat:before {
  content: "";
}

.glyphicon-refresh:before {
  content: "";
}

.glyphicon-list-alt:before {
  content: "";
}

.glyphicon-lock:before {
  content: "";
}

.glyphicon-flag:before {
  content: "";
}

.glyphicon-headphones:before {
  content: "";
}

.glyphicon-volume-off:before {
  content: "";
}

.glyphicon-volume-down:before {
  content: "";
}

.glyphicon-volume-up:before {
  content: "";
}

.glyphicon-qrcode:before {
  content: "";
}

.glyphicon-barcode:before {
  content: "";
}

.glyphicon-tag:before {
  content: "";
}

.glyphicon-tags:before {
  content: "";
}

.glyphicon-book:before {
  content: "";
}

.glyphicon-bookmark:before {
  content: "";
}

.glyphicon-print:before {
  content: "";
}

.glyphicon-camera:before {
  content: "";
}

.glyphicon-font:before {
  content: "";
}

.glyphicon-bold:before {
  content: "";
}

.glyphicon-italic:before {
  content: "";
}

.glyphicon-text-height:before {
  content: "";
}

.glyphicon-text-width:before {
  content: "";
}

.glyphicon-align-left:before {
  content: "";
}

.glyphicon-align-center:before {
  content: "";
}

.glyphicon-align-right:before {
  content: "";
}

.glyphicon-align-justify:before {
  content: "";
}

.glyphicon-list:before {
  content: "";
}

.glyphicon-indent-left:before {
  content: "";
}

.glyphicon-indent-right:before {
  content: "";
}

.glyphicon-facetime-video:before {
  content: "";
}

.glyphicon-picture:before {
  content: "";
}

.glyphicon-map-marker:before {
  content: "";
}

.glyphicon-adjust:before {
  content: "";
}

.glyphicon-tint:before {
  content: "";
}

.glyphicon-edit:before {
  content: "";
}

.glyphicon-share:before {
  content: "";
}

.glyphicon-check:before {
  content: "";
}

.glyphicon-move:before {
  content: "";
}

.glyphicon-step-backward:before {
  content: "";
}

.glyphicon-fast-backward:before {
  content: "";
}

.glyphicon-backward:before {
  content: "";
}

.glyphicon-play:before {
  content: "";
}

.glyphicon-pause:before {
  content: "";
}

.glyphicon-stop:before {
  content: "";
}

.glyphicon-forward:before {
  content: "";
}

.glyphicon-fast-forward:before {
  content: "";
}

.glyphicon-step-forward:before {
  content: "";
}

.glyphicon-eject:before {
  content: "";
}

.glyphicon-chevron-left:before {
  content: "";
}

.glyphicon-chevron-right:before {
  content: "";
}

.glyphicon-plus-sign:before {
  content: "";
}

.glyphicon-minus-sign:before {
  content: "";
}

.glyphicon-remove-sign:before {
  content: "";
}

.glyphicon-ok-sign:before {
  content: "";
}

.glyphicon-question-sign:before {
  content: "";
}

.glyphicon-info-sign:before {
  content: "";
}

.glyphicon-screenshot:before {
  content: "";
}

.glyphicon-remove-circle:before {
  content: "";
}

.glyphicon-ok-circle:before {
  content: "";
}

.glyphicon-ban-circle:before {
  content: "";
}

.glyphicon-arrow-left:before {
  content: "";
}

.glyphicon-arrow-right:before {
  content: "";
}

.glyphicon-arrow-up:before {
  content: "";
}

.glyphicon-arrow-down:before {
  content: "";
}

.glyphicon-share-alt:before {
  content: "";
}

.glyphicon-resize-full:before {
  content: "";
}

.glyphicon-resize-small:before {
  content: "";
}

.glyphicon-exclamation-sign:before {
  content: "";
}

.glyphicon-gift:before {
  content: "";
}

.glyphicon-leaf:before {
  content: "";
}

.glyphicon-fire:before {
  content: "";
}

.glyphicon-eye-open:before {
  content: "";
}

.glyphicon-eye-close:before {
  content: "";
}

.glyphicon-warning-sign:before {
  content: "";
}

.glyphicon-plane:before {
  content: "";
}

.glyphicon-calendar:before {
  content: "";
}

.glyphicon-random:before {
  content: "";
}

.glyphicon-comment:before {
  content: "";
}

.glyphicon-magnet:before {
  content: "";
}

.glyphicon-chevron-up:before {
  content: "";
}

.glyphicon-chevron-down:before {
  content: "";
}

.glyphicon-retweet:before {
  content: "";
}

.glyphicon-shopping-cart:before {
  content: "";
}

.glyphicon-folder-close:before {
  content: "";
}

.glyphicon-folder-open:before {
  content: "";
}

.glyphicon-resize-vertical:before {
  content: "";
}

.glyphicon-resize-horizontal:before {
  content: "";
}

.glyphicon-hdd:before {
  content: "";
}

.glyphicon-bullhorn:before {
  content: "";
}

.glyphicon-bell:before {
  content: "";
}

.glyphicon-certificate:before {
  content: "";
}

.glyphicon-thumbs-up:before {
  content: "";
}

.glyphicon-thumbs-down:before {
  content: "";
}

.glyphicon-hand-right:before {
  content: "";
}

.glyphicon-hand-left:before {
  content: "";
}

.glyphicon-hand-up:before {
  content: "";
}

.glyphicon-hand-down:before {
  content: "";
}

.glyphicon-circle-arrow-right:before {
  content: "";
}

.glyphicon-circle-arrow-left:before {
  content: "";
}

.glyphicon-circle-arrow-up:before {
  content: "";
}

.glyphicon-circle-arrow-down:before {
  content: "";
}

.glyphicon-globe:before {
  content: "";
}

.glyphicon-wrench:before {
  content: "";
}

.glyphicon-tasks:before {
  content: "";
}

.glyphicon-filter:before {
  content: "";
}

.glyphicon-briefcase:before {
  content: "";
}

.glyphicon-fullscreen:before {
  content: "";
}

.glyphicon-dashboard:before {
  content: "";
}

.glyphicon-paperclip:before {
  content: "";
}

.glyphicon-heart-empty:before {
  content: "";
}

.glyphicon-link:before {
  content: "";
}

.glyphicon-phone:before {
  content: "";
}

.glyphicon-pushpin:before {
  content: "";
}

.glyphicon-usd:before {
  content: "";
}

.glyphicon-gbp:before {
  content: "";
}

.glyphicon-sort:before {
  content: "";
}

.glyphicon-sort-by-alphabet:before {
  content: "";
}

.glyphicon-sort-by-alphabet-alt:before {
  content: "";
}

.glyphicon-sort-by-order:before {
  content: "";
}

.glyphicon-sort-by-order-alt:before {
  content: "";
}

.glyphicon-sort-by-attributes:before {
  content: "";
}

.glyphicon-sort-by-attributes-alt:before {
  content: "";
}

.glyphicon-unchecked:before {
  content: "";
}

.glyphicon-expand:before {
  content: "";
}

.glyphicon-collapse-down:before {
  content: "";
}

.glyphicon-collapse-up:before {
  content: "";
}

.glyphicon-log-in:before {
  content: "";
}

.glyphicon-flash:before {
  content: "";
}

.glyphicon-log-out:before {
  content: "";
}

.glyphicon-new-window:before {
  content: "";
}

.glyphicon-record:before {
  content: "";
}

.glyphicon-save:before {
  content: "";
}

.glyphicon-open:before {
  content: "";
}

.glyphicon-saved:before {
  content: "";
}

.glyphicon-import:before {
  content: "";
}

.glyphicon-export:before {
  content: "";
}

.glyphicon-send:before {
  content: "";
}

.glyphicon-floppy-disk:before {
  content: "";
}

.glyphicon-floppy-saved:before {
  content: "";
}

.glyphicon-floppy-remove:before {
  content: "";
}

.glyphicon-floppy-save:before {
  content: "";
}

.glyphicon-floppy-open:before {
  content: "";
}

.glyphicon-credit-card:before {
  content: "";
}

.glyphicon-transfer:before {
  content: "";
}

.glyphicon-cutlery:before {
  content: "";
}

.glyphicon-header:before {
  content: "";
}

.glyphicon-compressed:before {
  content: "";
}

.glyphicon-earphone:before {
  content: "";
}

.glyphicon-phone-alt:before {
  content: "";
}

.glyphicon-tower:before {
  content: "";
}

.glyphicon-stats:before {
  content: "";
}

.glyphicon-sd-video:before {
  content: "";
}

.glyphicon-hd-video:before {
  content: "";
}

.glyphicon-subtitles:before {
  content: "";
}

.glyphicon-sound-stereo:before {
  content: "";
}

.glyphicon-sound-dolby:before {
  content: "";
}

.glyphicon-sound-5-1:before {
  content: "";
}

.glyphicon-sound-6-1:before {
  content: "";
}

.glyphicon-sound-7-1:before {
  content: "";
}

.glyphicon-copyright-mark:before {
  content: "";
}

.glyphicon-registration-mark:before {
  content: "";
}

.glyphicon-cloud-download:before {
  content: "";
}

.glyphicon-cloud-upload:before {
  content: "";
}

.glyphicon-tree-conifer:before {
  content: "";
}

.glyphicon-tree-deciduous:before {
  content: "";
}

.glyphicon-cd:before {
  content: "";
}

.glyphicon-save-file:before {
  content: "";
}

.glyphicon-open-file:before {
  content: "";
}

.glyphicon-level-up:before {
  content: "";
}

.glyphicon-copy:before {
  content: "";
}

.glyphicon-paste:before {
  content: "";
}

.glyphicon-alert:before {
  content: "";
}

.glyphicon-equalizer:before {
  content: "";
}

.glyphicon-king:before {
  content: "";
}

.glyphicon-queen:before {
  content: "";
}

.glyphicon-pawn:before {
  content: "";
}

.glyphicon-bishop:before {
  content: "";
}

.glyphicon-knight:before {
  content: "";
}

.glyphicon-baby-formula:before {
  content: "";
}

.glyphicon-tent:before {
  content: "⛺";
}

.glyphicon-blackboard:before {
  content: "";
}

.glyphicon-bed:before {
  content: "";
}

.glyphicon-apple:before {
  content: "";
}

.glyphicon-erase:before {
  content: "";
}

.glyphicon-hourglass:before {
  content: "⌛";
}

.glyphicon-lamp:before {
  content: "";
}

.glyphicon-duplicate:before {
  content: "";
}

.glyphicon-piggy-bank:before {
  content: "";
}

.glyphicon-scissors:before {
  content: "";
}

.glyphicon-bitcoin:before, .glyphicon-btc:before, .glyphicon-xbt:before {
  content: "";
}

.glyphicon-yen:before, .glyphicon-jpy:before {
  content: "¥";
}

.glyphicon-ruble:before, .glyphicon-rub:before {
  content: "₽";
}

.glyphicon-scale:before {
  content: "";
}

.glyphicon-ice-lolly:before {
  content: "";
}

.glyphicon-ice-lolly-tasted:before {
  content: "";
}

.glyphicon-education:before {
  content: "";
}

.glyphicon-option-horizontal:before {
  content: "";
}

.glyphicon-option-vertical:before {
  content: "";
}

.glyphicon-menu-hamburger:before {
  content: "";
}

.glyphicon-modal-window:before {
  content: "";
}

.glyphicon-oil:before {
  content: "";
}

.glyphicon-grain:before {
  content: "";
}

.glyphicon-sunglasses:before {
  content: "";
}

.glyphicon-text-size:before {
  content: "";
}

.glyphicon-text-color:before {
  content: "";
}

.glyphicon-text-background:before {
  content: "";
}

.glyphicon-object-align-top:before {
  content: "";
}

.glyphicon-object-align-bottom:before {
  content: "";
}

.glyphicon-object-align-horizontal:before {
  content: "";
}

.glyphicon-object-align-left:before {
  content: "";
}

.glyphicon-object-align-vertical:before {
  content: "";
}

.glyphicon-object-align-right:before {
  content: "";
}

.glyphicon-triangle-right:before {
  content: "";
}

.glyphicon-triangle-left:before {
  content: "";
}

.glyphicon-triangle-bottom:before {
  content: "";
}

.glyphicon-triangle-top:before {
  content: "";
}

.glyphicon-console:before {
  content: "";
}

.glyphicon-superscript:before {
  content: "";
}

.glyphicon-subscript:before {
  content: "";
}

.glyphicon-menu-left:before {
  content: "";
}

.glyphicon-menu-right:before {
  content: "";
}

.glyphicon-menu-down:before {
  content: "";
}

.glyphicon-menu-up:before {
  content: "";
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 10px;
}

body {
  color: #333;
  background-color: #fff;
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.42857;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #00313a;
  text-decoration: none;
}

a:hover, a:focus {
  color: #000;
  text-decoration: underline;
}

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

.img-responsive {
  max-width: 100%;
  height: auto;
  display: block;
}

.img-rounded {
  border-radius: 0;
}

.img-thumbnail {
  -o-transition: all .2s ease-in-out;
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  padding: 4px;
  line-height: 1.42857;
  transition: all .2s ease-in-out;
  display: inline-block;
}

.img-circle {
  border-radius: 50%;
}

hr {
  border: 0;
  border-top: 1px solid #e7e7e7;
  margin-top: 22px;
  margin-bottom: 22px;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

[role="button"] {
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: inherit;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
}

h1 small, h1 .small, h2 small, h2 .small, h3 small, h3 .small, h4 small, h4 .small, h5 small, h5 .small, h6 small, h6 .small, .h1 small, .h1 .small, .h2 small, .h2 .small, .h3 small, .h3 .small, .h4 small, .h4 .small, .h5 small, .h5 .small, .h6 small, .h6 .small {
  color: #a5a5a5;
  font-weight: 400;
  line-height: 1;
}

h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 22px;
  margin-bottom: 11px;
}

h1 small, h1 .small, .h1 small, .h1 .small, h2 small, h2 .small, .h2 small, .h2 .small, h3 small, h3 .small, .h3 small, .h3 .small {
  font-size: 65%;
}

h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 11px;
  margin-bottom: 11px;
}

h4 small, h4 .small, .h4 small, .h4 .small, h5 small, h5 .small, .h5 small, .h5 .small, h6 small, h6 .small, .h6 small, .h6 .small {
  font-size: 75%;
}

h1, .h1 {
  font-size: 41px;
}

h2, .h2 {
  font-size: 34px;
}

h3, .h3 {
  font-size: 28px;
}

h4, .h4 {
  font-size: 20px;
}

h5, .h5 {
  font-size: 16px;
}

h6, .h6 {
  font-size: 14px;
}

p {
  margin: 0 0 11px;
}

.lead {
  margin-bottom: 22px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .lead {
    font-size: 24px;
  }
}

small, .small {
  font-size: 87%;
}

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase, .initialism {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-muted {
  color: #a5a5a5;
}

.text-primary {
  color: #00313a;
}

a.text-primary:hover, a.text-primary:focus {
  color: #000607;
}

.text-success {
  color: #3c763d;
}

a.text-success:hover, a.text-success:focus {
  color: #2b542c;
}

.text-info {
  color: #31708f;
}

a.text-info:hover, a.text-info:focus {
  color: #245269;
}

.text-warning {
  color: #8a6d3b;
}

a.text-warning:hover, a.text-warning:focus {
  color: #66512c;
}

.text-danger {
  color: #a94442;
}

a.text-danger:hover, a.text-danger:focus {
  color: #843534;
}

.bg-primary {
  color: #fff;
  background-color: #00313a;
}

a.bg-primary:hover, a.bg-primary:focus {
  background-color: #000607;
}

.bg-success {
  background-color: #dff0d8;
}

a.bg-success:hover, a.bg-success:focus {
  background-color: #c1e2b3;
}

.bg-info {
  background-color: #d9edf7;
}

a.bg-info:hover, a.bg-info:focus {
  background-color: #afd9ee;
}

.bg-warning {
  background-color: #fcf8e3;
}

a.bg-warning:hover, a.bg-warning:focus {
  background-color: #f7ecb5;
}

.bg-danger {
  background-color: #f2dede;
}

a.bg-danger:hover, a.bg-danger:focus {
  background-color: #e4b9b9;
}

.page-header {
  border-bottom: 1px solid #e7e7e7;
  margin: 44px 0 22px;
  padding-bottom: 10px;
}

ul, ol {
  margin-top: 0;
  margin-bottom: 11px;
}

ul ul, ul ol, ol ul, ol ol {
  margin-bottom: 0;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  margin-left: -5px;
  padding-left: 0;
  list-style: none;
}

.list-inline > li {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
}

dl {
  margin-top: 0;
  margin-bottom: 22px;
}

dt, dd {
  line-height: 1.42857;
}

dt {
  font-weight: 700;
}

dd {
  margin-left: 0;
}

.dl-horizontal dd:before, .dl-horizontal dd:after {
  content: " ";
  display: table;
}

.dl-horizontal dd:after {
  clear: both;
}

@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .dl-horizontal dd {
    margin-left: 180px;
  }
}

abbr[title], abbr[data-original-title] {
  cursor: help;
}

.initialism {
  font-size: 90%;
}

blockquote {
  border-left: 5px solid #e7e7e7;
  margin: 0 0 22px;
  padding: 11px 22px;
  font-size: 20px;
}

blockquote p:last-child, blockquote ul:last-child, blockquote ol:last-child {
  margin-bottom: 0;
}

blockquote footer, blockquote small, blockquote .small {
  color: #a5a5a5;
  font-size: 80%;
  line-height: 1.42857;
  display: block;
}

blockquote footer:before, blockquote small:before, blockquote .small:before {
  content: "— ";
}

.blockquote-reverse, blockquote.pull-right {
  text-align: right;
  border-left: 0;
  border-right: 5px solid #e7e7e7;
  padding-left: 0;
  padding-right: 15px;
}

.blockquote-reverse footer:before, .blockquote-reverse small:before, .blockquote-reverse .small:before, blockquote.pull-right footer:before, blockquote.pull-right small:before, blockquote.pull-right .small:before {
  content: "";
}

.blockquote-reverse footer:after, .blockquote-reverse small:after, .blockquote-reverse .small:after, blockquote.pull-right footer:after, blockquote.pull-right small:after, blockquote.pull-right .small:after {
  content: " —";
}

address {
  margin-bottom: 22px;
  font-style: normal;
  line-height: 1.42857;
}

code, kbd, pre, samp {
  font-family: Menlo, Monaco, Consolas, Courier New, monospace;
}

code {
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 0;
  padding: 2px 4px;
  font-size: 90%;
}

kbd {
  color: #fff;
  background-color: #333;
  border-radius: 0;
  padding: 2px 4px;
  font-size: 90%;
  box-shadow: inset 0 -1px rgba(0, 0, 0, .25);
}

kbd kbd {
  box-shadow: none;
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 0;
  margin: 0 0 11px;
  padding: 10.5px;
  font-size: 15px;
  line-height: 1.42857;
  display: block;
}

pre code {
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  padding: 0;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container:before, .container:after {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

.container-fluid:after {
  clear: both;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.row-no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.row-no-gutters [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-1 {
  width: 8.33333%;
}

.col-xs-2 {
  width: 16.6667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.3333%;
}

.col-xs-5 {
  width: 41.6667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.3333%;
}

.col-xs-8 {
  width: 66.6667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.3333%;
}

.col-xs-11 {
  width: 91.6667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.33333%;
}

.col-xs-pull-2 {
  right: 16.6667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.3333%;
}

.col-xs-pull-5 {
  right: 41.6667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.3333%;
}

.col-xs-pull-8 {
  right: 66.6667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.3333%;
}

.col-xs-pull-11 {
  right: 91.6667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.33333%;
}

.col-xs-push-2 {
  left: 16.6667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.3333%;
}

.col-xs-push-5 {
  left: 41.6667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.3333%;
}

.col-xs-push-8 {
  left: 66.6667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.3333%;
}

.col-xs-push-11 {
  left: 91.6667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-offset-2 {
  margin-left: 16.6667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.3333%;
}

.col-xs-offset-5 {
  margin-left: 41.6667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.3333%;
}

.col-xs-offset-8 {
  margin-left: 66.6667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.3333%;
}

.col-xs-offset-11 {
  margin-left: 91.6667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }

  .col-sm-1 {
    width: 8.33333%;
  }

  .col-sm-2 {
    width: 16.6667%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.3333%;
  }

  .col-sm-5 {
    width: 41.6667%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.3333%;
  }

  .col-sm-8 {
    width: 66.6667%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.3333%;
  }

  .col-sm-11 {
    width: 91.6667%;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-pull-1 {
    right: 8.33333%;
  }

  .col-sm-pull-2 {
    right: 16.6667%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-4 {
    right: 33.3333%;
  }

  .col-sm-pull-5 {
    right: 41.6667%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-7 {
    right: 58.3333%;
  }

  .col-sm-pull-8 {
    right: 66.6667%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-10 {
    right: 83.3333%;
  }

  .col-sm-pull-11 {
    right: 91.6667%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-push-1 {
    left: 8.33333%;
  }

  .col-sm-push-2 {
    left: 16.6667%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-4 {
    left: 33.3333%;
  }

  .col-sm-push-5 {
    left: 41.6667%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-7 {
    left: 58.3333%;
  }

  .col-sm-push-8 {
    left: 66.6667%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-10 {
    left: 83.3333%;
  }

  .col-sm-push-11 {
    left: 91.6667%;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.6667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.3333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.6667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.3333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.6667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.3333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.6667%;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }

  .col-md-1 {
    width: 8.33333%;
  }

  .col-md-2 {
    width: 16.6667%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.3333%;
  }

  .col-md-5 {
    width: 41.6667%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.3333%;
  }

  .col-md-8 {
    width: 66.6667%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.3333%;
  }

  .col-md-11 {
    width: 91.6667%;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-pull-1 {
    right: 8.33333%;
  }

  .col-md-pull-2 {
    right: 16.6667%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-4 {
    right: 33.3333%;
  }

  .col-md-pull-5 {
    right: 41.6667%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-7 {
    right: 58.3333%;
  }

  .col-md-pull-8 {
    right: 66.6667%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-10 {
    right: 83.3333%;
  }

  .col-md-pull-11 {
    right: 91.6667%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-push-1 {
    left: 8.33333%;
  }

  .col-md-push-2 {
    left: 16.6667%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-4 {
    left: 33.3333%;
  }

  .col-md-push-5 {
    left: 41.6667%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-7 {
    left: 58.3333%;
  }

  .col-md-push-8 {
    left: 66.6667%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-10 {
    left: 83.3333%;
  }

  .col-md-push-11 {
    left: 91.6667%;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333%;
  }

  .col-md-offset-2 {
    margin-left: 16.6667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.3333%;
  }

  .col-md-offset-5 {
    margin-left: 41.6667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.3333%;
  }

  .col-md-offset-8 {
    margin-left: 66.6667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.3333%;
  }

  .col-md-offset-11 {
    margin-left: 91.6667%;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }

  .col-lg-1 {
    width: 8.33333%;
  }

  .col-lg-2 {
    width: 16.6667%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-4 {
    width: 33.3333%;
  }

  .col-lg-5 {
    width: 41.6667%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-7 {
    width: 58.3333%;
  }

  .col-lg-8 {
    width: 66.6667%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-10 {
    width: 83.3333%;
  }

  .col-lg-11 {
    width: 91.6667%;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-pull-1 {
    right: 8.33333%;
  }

  .col-lg-pull-2 {
    right: 16.6667%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-4 {
    right: 33.3333%;
  }

  .col-lg-pull-5 {
    right: 41.6667%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-7 {
    right: 58.3333%;
  }

  .col-lg-pull-8 {
    right: 66.6667%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-10 {
    right: 83.3333%;
  }

  .col-lg-pull-11 {
    right: 91.6667%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-push-1 {
    left: 8.33333%;
  }

  .col-lg-push-2 {
    left: 16.6667%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-4 {
    left: 33.3333%;
  }

  .col-lg-push-5 {
    left: 41.6667%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-7 {
    left: 58.3333%;
  }

  .col-lg-push-8 {
    left: 66.6667%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-10 {
    left: 83.3333%;
  }

  .col-lg-push-11 {
    left: 91.6667%;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.6667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.3333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.6667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.3333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.6667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.3333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.6667%;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

table {
  background-color: rgba(0, 0, 0, 0);
}

table col[class*="col-"] {
  float: none;
  display: table-column;
  position: static;
}

table td[class*="col-"], table th[class*="col-"] {
  float: none;
  display: table-cell;
  position: static;
}

caption {
  color: #a5a5a5;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 22px;
}

.table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
  vertical-align: top;
  border-top: 1px solid #ddd;
  padding: 8px;
  line-height: 1.42857;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.table > caption + thead > tr:first-child > th, .table > caption + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > td, .table > thead:first-child > tr:first-child > th, .table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.table > tbody + tbody {
  border-top: 2px solid #ddd;
}

.table .table {
  background-color: #fff;
}

.table-condensed > thead > tr > th, .table-condensed > thead > tr > td, .table-condensed > tbody > tr > th, .table-condensed > tbody > tr > td, .table-condensed > tfoot > tr > th, .table-condensed > tfoot > tr > td {
  padding: 5px;
}

.table-bordered, .table-bordered > thead > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > tfoot > tr > td {
  border: 1px solid #ddd;
}

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
  background-color: #f9f9f9;
}

.table-hover > tbody > tr:hover, .table > thead > tr > td.active, .table > thead > tr > th.active, .table > thead > tr.active > td, .table > thead > tr.active > th, .table > tbody > tr > td.active, .table > tbody > tr > th.active, .table > tbody > tr.active > td, .table > tbody > tr.active > th, .table > tfoot > tr > td.active, .table > tfoot > tr > th.active, .table > tfoot > tr.active > td, .table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}

.table-hover > tbody > tr > td.active:hover, .table-hover > tbody > tr > th.active:hover, .table-hover > tbody > tr.active:hover > td, .table-hover > tbody > tr:hover > .active, .table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}

.table > thead > tr > td.success, .table > thead > tr > th.success, .table > thead > tr.success > td, .table > thead > tr.success > th, .table > tbody > tr > td.success, .table > tbody > tr > th.success, .table > tbody > tr.success > td, .table > tbody > tr.success > th, .table > tfoot > tr > td.success, .table > tfoot > tr > th.success, .table > tfoot > tr.success > td, .table > tfoot > tr.success > th {
  background-color: #dff0d8;
}

.table-hover > tbody > tr > td.success:hover, .table-hover > tbody > tr > th.success:hover, .table-hover > tbody > tr.success:hover > td, .table-hover > tbody > tr:hover > .success, .table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}

.table > thead > tr > td.info, .table > thead > tr > th.info, .table > thead > tr.info > td, .table > thead > tr.info > th, .table > tbody > tr > td.info, .table > tbody > tr > th.info, .table > tbody > tr.info > td, .table > tbody > tr.info > th, .table > tfoot > tr > td.info, .table > tfoot > tr > th.info, .table > tfoot > tr.info > td, .table > tfoot > tr.info > th {
  background-color: #d9edf7;
}

.table-hover > tbody > tr > td.info:hover, .table-hover > tbody > tr > th.info:hover, .table-hover > tbody > tr.info:hover > td, .table-hover > tbody > tr:hover > .info, .table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}

.table > thead > tr > td.warning, .table > thead > tr > th.warning, .table > thead > tr.warning > td, .table > thead > tr.warning > th, .table > tbody > tr > td.warning, .table > tbody > tr > th.warning, .table > tbody > tr.warning > td, .table > tbody > tr.warning > th, .table > tfoot > tr > td.warning, .table > tfoot > tr > th.warning, .table > tfoot > tr.warning > td, .table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}

.table-hover > tbody > tr > td.warning:hover, .table-hover > tbody > tr > th.warning:hover, .table-hover > tbody > tr.warning:hover > td, .table-hover > tbody > tr:hover > .warning, .table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}

.table > thead > tr > td.danger, .table > thead > tr > th.danger, .table > thead > tr.danger > td, .table > thead > tr.danger > th, .table > tbody > tr > td.danger, .table > tbody > tr > th.danger, .table > tbody > tr.danger > td, .table > tbody > tr.danger > th, .table > tfoot > tr > td.danger, .table > tfoot > tr > th.danger, .table > tfoot > tr.danger > td, .table > tfoot > tr.danger > th {
  background-color: #f2dede;
}

.table-hover > tbody > tr > td.danger:hover, .table-hover > tbody > tr > th.danger:hover, .table-hover > tbody > tr.danger:hover > td, .table-hover > tbody > tr:hover > .danger, .table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}

.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
    margin-bottom: 16.5px;
    overflow-y: hidden;
  }

  .table-responsive > .table {
    margin-bottom: 0;
  }

  .table-responsive > .table > thead > tr > th, .table-responsive > .table > thead > tr > td, .table-responsive > .table > tbody > tr > th, .table-responsive > .table > tbody > tr > td, .table-responsive > .table > tfoot > tr > th, .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }

  .table-responsive > .table-bordered {
    border: 0;
  }

  .table-responsive > .table-bordered > thead > tr > th:first-child, .table-responsive > .table-bordered > thead > tr > td:first-child, .table-responsive > .table-bordered > tbody > tr > th:first-child, .table-responsive > .table-bordered > tbody > tr > td:first-child, .table-responsive > .table-bordered > tfoot > tr > th:first-child, .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }

  .table-responsive > .table-bordered > thead > tr > th:last-child, .table-responsive > .table-bordered > thead > tr > td:last-child, .table-responsive > .table-bordered > tbody > tr > th:last-child, .table-responsive > .table-bordered > tbody > tr > td:last-child, .table-responsive > .table-bordered > tfoot > tr > th:last-child, .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }

  .table-responsive > .table-bordered > tbody > tr:last-child > th, .table-responsive > .table-bordered > tbody > tr:last-child > td, .table-responsive > .table-bordered > tfoot > tr:last-child > th, .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  font-size: 24px;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 22px;
  padding: 0;
  display: block;
}

label {
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  display: inline-block;
}

input[type="search"] {
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
}

input[type="radio"], input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9 ;
  line-height: normal;
}

input[type="radio"][disabled], input[type="radio"].disabled, fieldset[disabled] input[type="radio"], input[type="checkbox"][disabled], input[type="checkbox"].disabled, fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed;
}

input[type="file"] {
  display: block;
}

input[type="range"] {
  width: 100%;
  display: block;
}

select[multiple], select[size] {
  height: auto;
}

input[type="file"]:focus, input[type="radio"]:focus, input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

output {
  color: #555;
  padding-top: 7px;
  font-size: 16px;
  line-height: 1.42857;
  display: block;
}

.form-control {
  width: 100%;
  height: 36px;
  color: #555;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control::-webkit-input-placeholder {
  color: #999;
}

.form-control::-ms-expand {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  opacity: 1;
  background-color: #e7e7e7;
}

.form-control[disabled], fieldset[disabled] .form-control {
  cursor: not-allowed;
}

textarea.form-control {
  height: auto;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control {
    line-height: 36px;
  }

  input[type="date"].input-sm, .input-group-sm > .input-group-btn > input[type="date"].btn, .input-group-sm input[type="date"], input[type="time"].input-sm, .input-group-sm > .input-group-btn > input[type="time"].btn, .input-group-sm input[type="time"], input[type="datetime-local"].input-sm, .input-group-sm > .input-group-btn > input[type="datetime-local"].btn, .input-group-sm input[type="datetime-local"], input[type="month"].input-sm, .input-group-sm > .input-group-btn > input[type="month"].btn, .input-group-sm input[type="month"] {
    line-height: 33px;
  }

  input[type="date"].input-lg, .input-group-lg > .input-group-btn > input[type="date"].btn, .input-group-lg input[type="date"], input[type="time"].input-lg, .input-group-lg > .input-group-btn > input[type="time"].btn, .input-group-lg input[type="time"], input[type="datetime-local"].input-lg, .input-group-lg > .input-group-btn > input[type="datetime-local"].btn, .input-group-lg input[type="datetime-local"], input[type="month"].input-lg, .input-group-lg > .input-group-btn > input[type="month"].btn, .input-group-lg input[type="month"] {
    line-height: 49px;
  }
}

.form-group {
  margin-bottom: 15px;
}

.radio, .checkbox {
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  position: relative;
}

.radio.disabled label, fieldset[disabled] .radio label, .checkbox.disabled label, fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}

.radio label, .checkbox label {
  min-height: 22px;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 20px;
  font-weight: 400;
}

.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
  margin-top: 4px \9 ;
  margin-left: -20px;
  position: absolute;
}

.radio + .radio, .checkbox + .checkbox {
  margin-top: -5px;
}

.radio-inline, .checkbox-inline {
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 20px;
  font-weight: 400;
  display: inline-block;
  position: relative;
}

.radio-inline.disabled, fieldset[disabled] .radio-inline, .checkbox-inline.disabled, fieldset[disabled] .checkbox-inline {
  cursor: not-allowed;
}

.radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

.form-control-static {
  min-height: 38px;
  margin-bottom: 0;
  padding-top: 7px;
  padding-bottom: 7px;
}

.form-control-static.input-lg, .input-group-lg > .form-control-static.form-control, .input-group-lg > .form-control-static.input-group-addon, .input-group-lg > .input-group-btn > .form-control-static.btn, .form-control-static.input-sm, .input-group-sm > .form-control-static.form-control, .input-group-sm > .form-control-static.input-group-addon, .input-group-sm > .input-group-btn > .form-control-static.btn {
  padding-left: 0;
  padding-right: 0;
}

.input-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn {
  height: 33px;
  border-radius: 0;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
}

select.input-sm, .input-group-sm > select.form-control, .input-group-sm > select.input-group-addon, .input-group-sm > .input-group-btn > select.btn {
  height: 33px;
  line-height: 33px;
}

textarea.input-sm, .input-group-sm > textarea.form-control, .input-group-sm > textarea.input-group-addon, .input-group-sm > .input-group-btn > textarea.btn, select[multiple].input-sm, .input-group-sm > select[multiple].form-control, .input-group-sm > select[multiple].input-group-addon, .input-group-sm > .input-group-btn > select[multiple].btn {
  height: auto;
}

.form-group-sm .form-control {
  height: 33px;
  border-radius: 0;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
}

.form-group-sm select.form-control {
  height: 33px;
  line-height: 33px;
}

.form-group-sm textarea.form-control, .form-group-sm select[multiple].form-control {
  height: auto;
}

.form-group-sm .form-control-static {
  height: 33px;
  min-height: 36px;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 1.5;
}

.input-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn {
  height: 49px;
  border-radius: 0;
  padding: 10px 16px;
  font-size: 20px;
  line-height: 1.33333;
}

select.input-lg, .input-group-lg > select.form-control, .input-group-lg > select.input-group-addon, .input-group-lg > .input-group-btn > select.btn {
  height: 49px;
  line-height: 49px;
}

textarea.input-lg, .input-group-lg > textarea.form-control, .input-group-lg > textarea.input-group-addon, .input-group-lg > .input-group-btn > textarea.btn, select[multiple].input-lg, .input-group-lg > select[multiple].form-control, .input-group-lg > select[multiple].input-group-addon, .input-group-lg > .input-group-btn > select[multiple].btn {
  height: auto;
}

.form-group-lg .form-control {
  height: 49px;
  border-radius: 0;
  padding: 10px 16px;
  font-size: 20px;
  line-height: 1.33333;
}

.form-group-lg select.form-control {
  height: 49px;
  line-height: 49px;
}

.form-group-lg textarea.form-control, .form-group-lg select[multiple].form-control {
  height: auto;
}

.form-group-lg .form-control-static {
  height: 49px;
  min-height: 42px;
  padding: 11px 16px;
  font-size: 20px;
  line-height: 1.33333;
}

.has-feedback {
  position: relative;
}

.has-feedback .form-control {
  padding-right: 45px;
}

.form-control-feedback {
  z-index: 2;
  width: 36px;
  height: 36px;
  text-align: center;
  pointer-events: none;
  line-height: 36px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.input-lg + .form-control-feedback, .input-group-lg > .form-control + .form-control-feedback, .input-group-lg > .input-group-addon + .form-control-feedback, .input-group-lg > .input-group-btn > .btn + .form-control-feedback, .input-group-lg + .form-control-feedback, .form-group-lg .form-control + .form-control-feedback {
  width: 49px;
  height: 49px;
  line-height: 49px;
}

.input-sm + .form-control-feedback, .input-group-sm > .form-control + .form-control-feedback, .input-group-sm > .input-group-addon + .form-control-feedback, .input-group-sm > .input-group-btn > .btn + .form-control-feedback, .input-group-sm + .form-control-feedback, .form-group-sm .form-control + .form-control-feedback {
  width: 33px;
  height: 33px;
  line-height: 33px;
}

.has-success .help-block, .has-success .control-label, .has-success .radio, .has-success .checkbox, .has-success .radio-inline, .has-success .checkbox-inline, .has-success.radio label, .has-success.checkbox label, .has-success.radio-inline label, .has-success.checkbox-inline label {
  color: #3c763d;
}

.has-success .form-control {
  border-color: #3c763d;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-success .form-control:focus {
  border-color: #2b542c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #67b168;
}

.has-success .input-group-addon {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #3c763d;
}

.has-success .form-control-feedback {
  color: #3c763d;
}

.has-warning .help-block, .has-warning .control-label, .has-warning .radio, .has-warning .checkbox, .has-warning .radio-inline, .has-warning .checkbox-inline, .has-warning.radio label, .has-warning.checkbox label, .has-warning.radio-inline label, .has-warning.checkbox-inline label {
  color: #8a6d3b;
}

.has-warning .form-control {
  border-color: #8a6d3b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-warning .form-control:focus {
  border-color: #66512c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #c0a16b;
}

.has-warning .input-group-addon {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #8a6d3b;
}

.has-warning .form-control-feedback {
  color: #8a6d3b;
}

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
  color: #a94442;
}

.has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-error .form-control:focus {
  border-color: #843534;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
}

.has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}

.has-error .form-control-feedback {
  color: #a94442;
}

.has-feedback label ~ .form-control-feedback {
  top: 27px;
}

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}

.help-block {
  color: #737373;
  margin-top: 5px;
  margin-bottom: 10px;
  display: block;
}

@media (min-width: 768px) {
  .form-inline .form-group {
    vertical-align: middle;
    margin-bottom: 0;
    display: inline-block;
  }

  .form-inline .form-control {
    width: auto;
    vertical-align: middle;
    display: inline-block;
  }

  .form-inline .form-control-static {
    display: inline-block;
  }

  .form-inline .input-group {
    vertical-align: middle;
    display: inline-table;
  }

  .form-inline .input-group .input-group-addon, .form-inline .input-group .input-group-btn, .form-inline .input-group .form-control {
    width: auto;
  }

  .form-inline .input-group > .form-control {
    width: 100%;
  }

  .form-inline .control-label {
    vertical-align: middle;
    margin-bottom: 0;
  }

  .form-inline .radio, .form-inline .checkbox {
    vertical-align: middle;
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
  }

  .form-inline .radio label, .form-inline .checkbox label {
    padding-left: 0;
  }

  .form-inline .radio input[type="radio"], .form-inline .checkbox input[type="checkbox"] {
    margin-left: 0;
    position: relative;
  }

  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
}

.form-horizontal .radio, .form-horizontal .checkbox {
  min-height: 29px;
}

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px;
}

.form-horizontal .form-group:before, .form-horizontal .form-group:after {
  content: " ";
  display: table;
}

.form-horizontal .form-group:after {
  clear: both;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px;
  }
}

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px;
}

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 20px;
  }

  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 14px;
  }
}

.btn {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0;
  margin-bottom: 0;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.42857;
  display: inline-block;
}

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn:hover, .btn:focus, .btn.focus {
  color: #333;
  text-decoration: none;
}

.btn:active, .btn.active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity= 65);
  opacity: .65;
  -webkit-box-shadow: none;
  box-shadow: none;
}

a.btn.disabled, fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-default:focus, .btn-default.focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}

.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle {
  color: #333;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #adadad;
}

.btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus, .open > .btn-default.dropdown-toggle:hover, .open > .btn-default.dropdown-toggle:focus, .open > .btn-default.dropdown-toggle.focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default .badge {
  color: #fff;
  background-color: #333;
}

.btn-primary {
  color: #fff;
  background-color: #00313a;
  border-color: #001b21;
}

.btn-primary:focus, .btn-primary.focus, .btn-primary:hover {
  color: #fff;
  background-color: #000607;
  border-color: #000;
}

.btn-primary:active, .btn-primary.active, .open > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #000607;
  background-image: none;
  border-color: #000;
}

.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus, .open > .btn-primary.dropdown-toggle:hover, .open > .btn-primary.dropdown-toggle:focus, .open > .btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary.focus {
  background-color: #00313a;
  border-color: #001b21;
}

.btn-primary .badge {
  color: #00313a;
  background-color: #fff;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #449d44;
  border-color: #255625;
}

.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.btn-success:active, .btn-success.active, .open > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  background-image: none;
  border-color: #398439;
}

.btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus, .open > .btn-success.dropdown-toggle:hover, .open > .btn-success.dropdown-toggle:focus, .open > .btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}

.btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, fieldset[disabled] .btn-success:hover, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success.focus {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #1b6d85;
}

.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}

.btn-info:active, .btn-info.active, .open > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  background-image: none;
  border-color: #269abc;
}

.btn-info:active:hover, .btn-info:active:focus, .btn-info:active.focus, .btn-info.active:hover, .btn-info.active:focus, .btn-info.active.focus, .open > .btn-info.dropdown-toggle:hover, .open > .btn-info.dropdown-toggle:focus, .open > .btn-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}

.btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus, fieldset[disabled] .btn-info:hover, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info.focus {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d;
}

.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.btn-warning:active, .btn-warning.active, .open > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  background-image: none;
  border-color: #d58512;
}

.btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:active.focus, .btn-warning.active:hover, .btn-warning.active:focus, .btn-warning.active.focus, .open > .btn-warning.dropdown-toggle:hover, .open > .btn-warning.dropdown-toggle:focus, .open > .btn-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}

.btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus, fieldset[disabled] .btn-warning:hover, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning.focus {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #761c19;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.btn-danger:active, .btn-danger.active, .open > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  background-image: none;
  border-color: #ac2925;
}

.btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:active.focus, .btn-danger.active:hover, .btn-danger.active:focus, .btn-danger.active.focus, .open > .btn-danger.dropdown-toggle:hover, .open > .btn-danger.dropdown-toggle:focus, .open > .btn-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}

.btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus, fieldset[disabled] .btn-danger:hover, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger.focus {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}

.btn-link {
  color: #00313a;
  border-radius: 0;
  font-weight: 400;
}

.btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled], fieldset[disabled] .btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
}

.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
  border-color: rgba(0, 0, 0, 0);
}

.btn-link:hover, .btn-link:focus {
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  text-decoration: underline;
}

.btn-link[disabled]:hover, .btn-link[disabled]:focus, fieldset[disabled] .btn-link:hover, fieldset[disabled] .btn-link:focus {
  color: #a5a5a5;
  text-decoration: none;
}

.btn-lg, .btn-group-lg > .btn {
  border-radius: 0;
  padding: 10px 16px;
  font-size: 20px;
  line-height: 1.33333;
}

.btn-sm, .btn-group-sm > .btn {
  border-radius: 0;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
}

.btn-xs, .btn-group-xs > .btn {
  border-radius: 0;
  padding: 1px 5px;
  font-size: 14px;
  line-height: 1.5;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: 5px;
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  height: 0;
  transition-property: height, visibility;
  transition-duration: .35s;
  transition-timing-function: ease;
  position: relative;
  overflow: hidden;
}

.caret {
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9 ;
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  margin-left: 2px;
  display: inline-block;
}

.dropup, .dropdown {
  position: relative;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropdown-menu {
  z-index: 1000;
  float: left;
  min-width: 160px;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 0;
  margin: 2px 0 0;
  padding: 5px 0;
  font-size: 16px;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, .176);
}

.dropdown-menu.pull-right {
  left: auto;
  right: 0;
}

.dropdown-menu .divider {
  height: 1px;
  background-color: #e5e5e5;
  margin: 10px 0;
  overflow: hidden;
}

.dropdown-menu > li > a {
  clear: both;
  color: #333;
  white-space: nowrap;
  padding: 3px 20px;
  font-weight: 400;
  line-height: 1.42857;
  display: block;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  color: #262626;
  background-color: #f5f5f5;
  text-decoration: none;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  color: #fff;
  background-color: #00313a;
  outline: 0;
  text-decoration: none;
}

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  color: #a5a5a5;
}

.dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  cursor: not-allowed;
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  text-decoration: none;
}

.open > .dropdown-menu {
  display: block;
}

.open > a {
  outline: 0;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

.dropdown-header {
  color: #a5a5a5;
  white-space: nowrap;
  padding: 3px 20px;
  font-size: 14px;
  line-height: 1.42857;
  display: block;
}

.dropdown-backdrop {
  z-index: 990;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.pull-right > .dropdown-menu {
  left: auto;
  right: 0;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9 ;
}

.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
  margin-bottom: 2px;
  top: auto;
  bottom: 100%;
}

@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    left: auto;
    right: 0;
  }

  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto;
  }
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  float: left;
  position: relative;
}

.btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 2;
}

.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  margin-left: -5px;
}

.btn-toolbar:before, .btn-toolbar:after {
  content: " ";
  display: table;
}

.btn-toolbar:after {
  clear: both;
}

.btn-toolbar .btn, .btn-toolbar .btn-group, .btn-toolbar .input-group {
  float: left;
}

.btn-toolbar > .btn, .btn-toolbar > .btn-group, .btn-toolbar > .input-group {
  margin-left: 5px;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn-group {
  float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group .dropdown-toggle:active, .btn-group.open .dropdown-toggle {
  outline: 0;
}

.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
}

.btn-group > .btn-lg + .dropdown-toggle, .btn-group-lg.btn-group > .btn + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
}

.btn-group.open .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.btn-group.open .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn .caret {
  margin-left: 0;
}

.btn-lg .caret, .btn-group-lg > .btn .caret {
  border-width: 5px 5px 0;
}

.dropup .btn-lg .caret, .dropup .btn-group-lg > .btn .caret {
  border-width: 0 5px 5px;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group, .btn-group-vertical > .btn-group > .btn {
  float: none;
  width: 100%;
  max-width: 100%;
  display: block;
}

.btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after {
  content: " ";
  display: table;
}

.btn-group-vertical > .btn-group:after {
  clear: both;
}

.btn-group-vertical > .btn-group > .btn {
  float: none;
}

.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child), .btn-group-vertical > .btn:first-child:not(:last-child), .btn-group-vertical > .btn:last-child:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-justified {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  display: table;
}

.btn-group-justified > .btn, .btn-group-justified > .btn-group {
  float: none;
  width: 1%;
  display: table-cell;
}

.btn-group-justified > .btn-group .btn {
  width: 100%;
}

.btn-group-justified > .btn-group .dropdown-menu {
  left: auto;
}

[data-toggle="buttons"] > .btn input[type="radio"], [data-toggle="buttons"] > .btn input[type="checkbox"], [data-toggle="buttons"] > .btn-group > .btn input[type="radio"], [data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.input-group {
  border-collapse: separate;
  display: table;
  position: relative;
}

.input-group[class*="col-"] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}

.input-group .form-control {
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
  position: relative;
}

.input-group .form-control:focus {
  z-index: 3;
}

.input-group-addon, .input-group-btn, .input-group .form-control {
  display: table-cell;
}

.input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child), .input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon, .input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon {
  color: #555;
  text-align: center;
  background-color: #e7e7e7;
  border: 1px solid #ccc;
  border-radius: 0;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.input-group-addon.input-sm, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .input-group-addon.btn {
  border-radius: 0;
  padding: 5px 10px;
  font-size: 14px;
}

.input-group-addon.input-lg, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .input-group-addon.btn {
  border-radius: 0;
  padding: 10px 16px;
  font-size: 20px;
}

.input-group-addon input[type="radio"], .input-group-addon input[type="checkbox"] {
  margin-top: 0;
}

.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group > .btn, .input-group-btn:first-child > .dropdown-toggle, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-addon:first-child {
  border-right: 0;
}

.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group > .btn, .input-group-btn:last-child > .dropdown-toggle, .input-group-btn:first-child > .btn:not(:first-child), .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-addon:last-child {
  border-left: 0;
}

.input-group-btn {
  white-space: nowrap;
  font-size: 0;
  position: relative;
}

.input-group-btn > .btn {
  position: relative;
}

.input-group-btn > .btn + .btn {
  margin-left: -1px;
}

.input-group-btn > .btn:hover, .input-group-btn > .btn:focus, .input-group-btn > .btn:active {
  z-index: 2;
}

.input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group {
  margin-right: -1px;
}

.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
  z-index: 2;
  margin-left: -1px;
}

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.nav:before, .nav:after {
  content: " ";
  display: table;
}

.nav:after {
  clear: both;
}

.nav > li {
  display: block;
  position: relative;
}

.nav > li > a {
  padding: 10px 15px;
  display: block;
  position: relative;
}

.nav > li > a:hover, .nav > li > a:focus {
  background-color: #e7e7e7;
  text-decoration: none;
}

.nav > li.disabled > a {
  color: #a5a5a5;
}

.nav > li.disabled > a:hover, .nav > li.disabled > a:focus {
  color: #a5a5a5;
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: #e7e7e7;
  border-color: #00313a;
}

.nav .nav-divider {
  height: 1px;
  background-color: #e5e5e5;
  margin: 10px 0;
  overflow: hidden;
}

.nav > li > a > img {
  max-width: none;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}

.nav-tabs > li > a {
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0;
  margin-right: 2px;
  line-height: 1.42857;
}

.nav-tabs > li > a:hover {
  border-color: #e7e7e7 #e7e7e7 #ddd;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: rgba(0, 0, 0, 0);
}

.nav-pills > li {
  float: left;
}

.nav-pills > li > a {
  border-radius: 0;
}

.nav-pills > li + li {
  margin-left: 2px;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: #fff;
  background-color: #00313a;
}

.nav-stacked > li {
  float: none;
}

.nav-stacked > li + li {
  margin-top: 2px;
  margin-left: 0;
}

.nav-justified, .nav-tabs.nav-justified {
  width: 100%;
}

.nav-justified > li, .nav-tabs.nav-justified > li {
  float: none;
}

.nav-justified > li > a, .nav-tabs.nav-justified > li > a {
  text-align: center;
  margin-bottom: 5px;
}

.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}

@media (min-width: 768px) {
  .nav-justified > li, .nav-tabs.nav-justified > li {
    width: 1%;
    display: table-cell;
  }

  .nav-justified > li > a, .nav-tabs.nav-justified > li > a {
    margin-bottom: 0;
  }
}

.nav-tabs-justified, .nav-tabs.nav-justified {
  border-bottom: 0;
}

.nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
  border-radius: 0;
  margin-right: 0;
}

.nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a, .nav-tabs-justified > .active > a:hover, .nav-tabs-justified > .active > a:focus {
  border: 1px solid #ddd;
}

@media (min-width: 768px) {
  .nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 0;
  }

  .nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a, .nav-tabs-justified > .active > a:hover, .nav-tabs-justified > .active > a:focus {
    border-bottom-color: #fff;
  }
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.navbar {
  min-height: 50px;
  border: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 22px;
  position: relative;
}

.navbar:before, .navbar:after {
  content: " ";
  display: table;
}

.navbar:after {
  clear: both;
}

@media (min-width: 768px) {
  .navbar {
    border-radius: 0;
  }
}

.navbar-header:before, .navbar-header:after {
  content: " ";
  display: table;
}

.navbar-header:after {
  clear: both;
}

@media (min-width: 768px) {
  .navbar-header {
    float: left;
  }
}

.navbar-collapse {
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid rgba(0, 0, 0, 0);
  padding-left: 15px;
  padding-right: 15px;
  overflow-x: visible;
  box-shadow: inset 0 1px rgba(255, 255, 255, .1);
}

.navbar-collapse:before, .navbar-collapse:after {
  content: " ";
  display: table;
}

.navbar-collapse:after {
  clear: both;
}

.navbar-collapse.in {
  overflow-y: auto;
}

@media (min-width: 768px) {
  .navbar-collapse {
    width: auto;
    box-shadow: none;
    border-top: 0;
  }

  .navbar-collapse.collapse {
    padding-bottom: 0;
    height: auto !important;
    display: block !important;
    overflow: visible !important;
  }

  .navbar-collapse.in {
    overflow-y: visible;
  }

  .navbar-fixed-top .navbar-collapse, .navbar-static-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    padding-left: 0;
    padding-right: 0;
  }
}

.navbar-fixed-top, .navbar-fixed-bottom {
  z-index: 1030;
  position: fixed;
  left: 0;
  right: 0;
}

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
  max-height: 340px;
}

@media (max-device-width: 480px) and (orientation: landscape) {
  .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    max-height: 200px;
  }
}

@media (min-width: 768px) {
  .navbar-fixed-top, .navbar-fixed-bottom {
    border-radius: 0;
  }
}

.navbar-fixed-top {
  border-width: 0 0 1px;
  top: 0;
}

.navbar-fixed-bottom {
  border-width: 1px 0 0;
  margin-bottom: 0;
  bottom: 0;
}

.container > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-header, .container-fluid > .navbar-collapse {
  margin-left: -15px;
  margin-right: -15px;
}

@media (min-width: 768px) {
  .container > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-header, .container-fluid > .navbar-collapse {
    margin-left: 0;
    margin-right: 0;
  }
}

.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px;
}

@media (min-width: 768px) {
  .navbar-static-top {
    border-radius: 0;
  }
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 14px 15px;
  font-size: 20px;
  line-height: 22px;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-brand > img {
  display: block;
}

@media (min-width: 768px) {
  .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: -15px;
  }
}

.navbar-toggle {
  float: right;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 15px;
  padding: 9px 10px;
  position: relative;
}

.navbar-toggle:focus {
  outline: 0;
}

.navbar-toggle .icon-bar {
  width: 22px;
  height: 2px;
  border-radius: 1px;
  display: block;
}

.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}

@media (min-width: 768px) {
  .navbar-toggle {
    display: none;
  }
}

.navbar-nav {
  margin: 7px -15px;
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 22px;
}

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    float: none;
    width: auto;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    margin-top: 0;
    position: static;
  }

  .navbar-nav .open .dropdown-menu > li > a, .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }

  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 22px;
  }

  .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none;
  }
}

@media (min-width: 768px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }

  .navbar-nav > li {
    float: left;
  }

  .navbar-nav > li > a {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

.navbar-form {
  border-top: 1px solid rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  margin: 7px -15px;
  padding: 10px 15px;
  box-shadow: inset 0 1px rgba(255, 255, 255, .1), 0 1px rgba(255, 255, 255, .1);
}

@media (min-width: 768px) {
  .navbar-form .form-group {
    vertical-align: middle;
    margin-bottom: 0;
    display: inline-block;
  }

  .navbar-form .form-control {
    width: auto;
    vertical-align: middle;
    display: inline-block;
  }

  .navbar-form .form-control-static {
    display: inline-block;
  }

  .navbar-form .input-group {
    vertical-align: middle;
    display: inline-table;
  }

  .navbar-form .input-group .input-group-addon, .navbar-form .input-group .input-group-btn, .navbar-form .input-group .form-control {
    width: auto;
  }

  .navbar-form .input-group > .form-control {
    width: 100%;
  }

  .navbar-form .control-label {
    vertical-align: middle;
    margin-bottom: 0;
  }

  .navbar-form .radio, .navbar-form .checkbox {
    vertical-align: middle;
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
  }

  .navbar-form .radio label, .navbar-form .checkbox label {
    padding-left: 0;
  }

  .navbar-form .radio input[type="radio"], .navbar-form .checkbox input[type="checkbox"] {
    margin-left: 0;
    position: relative;
  }

  .navbar-form .has-feedback .form-control-feedback {
    top: 0;
  }
}

@media (max-width: 767px) {
  .navbar-form .form-group {
    margin-bottom: 5px;
  }

  .navbar-form .form-group:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .navbar-form {
    width: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.navbar-nav > li > .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 0;
}

.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  border-radius: 0;
  margin-bottom: 0;
}

.navbar-btn {
  margin-top: 7px;
  margin-bottom: 7px;
}

.navbar-btn.btn-sm, .btn-group-sm > .navbar-btn.btn {
  margin-top: 8.5px;
  margin-bottom: 8.5px;
}

.navbar-btn.btn-xs, .btn-group-xs > .navbar-btn.btn, .navbar-text {
  margin-top: 14px;
  margin-bottom: 14px;
}

@media (min-width: 768px) {
  .navbar-text {
    float: left;
    margin-left: 15px;
    margin-right: 15px;
  }

  .navbar-left {
    float: left !important;
  }

  .navbar-right {
    margin-right: -15px;
    float: right !important;
  }

  .navbar-right ~ .navbar-right {
    margin-right: 0;
  }
}

.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
}

.navbar-default .navbar-brand {
  color: #777;
}

.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
  color: #5e5e5e;
  background-color: rgba(0, 0, 0, 0);
}

.navbar-default .navbar-text, .navbar-default .navbar-nav > li > a {
  color: #777;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  color: #333;
  background-color: rgba(0, 0, 0, 0);
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: #555;
  background-color: #e7e7e7;
}

.navbar-default .navbar-nav > .disabled > a, .navbar-default .navbar-nav > .disabled > a:hover, .navbar-default .navbar-nav > .disabled > a:focus {
  color: #ccc;
  background-color: rgba(0, 0, 0, 0);
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  color: #555;
  background-color: #e7e7e7;
}

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #777;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #333;
    background-color: rgba(0, 0, 0, 0);
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #555;
    background-color: #e7e7e7;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #ccc;
    background-color: rgba(0, 0, 0, 0);
  }
}

.navbar-default .navbar-toggle {
  border-color: #ddd;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background-color: #ddd;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #888;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  border-color: #e7e7e7;
}

.navbar-default .navbar-link {
  color: #777;
}

.navbar-default .navbar-link:hover {
  color: #333;
}

.navbar-default .btn-link {
  color: #777;
}

.navbar-default .btn-link:hover, .navbar-default .btn-link:focus {
  color: #333;
}

.navbar-default .btn-link[disabled]:hover, .navbar-default .btn-link[disabled]:focus, fieldset[disabled] .navbar-default .btn-link:hover, fieldset[disabled] .navbar-default .btn-link:focus {
  color: #ccc;
}

.navbar-inverse {
  background-color: #222;
  border-color: #090909;
}

.navbar-inverse .navbar-brand {
  color: #cbcbcb;
}

.navbar-inverse .navbar-brand:hover, .navbar-inverse .navbar-brand:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
}

.navbar-inverse .navbar-text, .navbar-inverse .navbar-nav > li > a {
  color: #cbcbcb;
}

.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
}

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
  color: #fff;
  background-color: #090909;
}

.navbar-inverse .navbar-nav > .disabled > a, .navbar-inverse .navbar-nav > .disabled > a:hover, .navbar-inverse .navbar-nav > .disabled > a:focus {
  color: #444;
  background-color: rgba(0, 0, 0, 0);
}

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  color: #fff;
  background-color: #090909;
}

@media (max-width: 767px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: #090909;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #090909;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #cbcbcb;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #fff;
    background-color: #090909;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #444;
    background-color: rgba(0, 0, 0, 0);
  }
}

.navbar-inverse .navbar-toggle {
  border-color: #333;
}

.navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
  background-color: #333;
}

.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff;
}

.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
  border-color: #101010;
}

.navbar-inverse .navbar-link {
  color: #cbcbcb;
}

.navbar-inverse .navbar-link:hover {
  color: #fff;
}

.navbar-inverse .btn-link {
  color: #cbcbcb;
}

.navbar-inverse .btn-link:hover, .navbar-inverse .btn-link:focus {
  color: #fff;
}

.navbar-inverse .btn-link[disabled]:hover, .navbar-inverse .btn-link[disabled]:focus, fieldset[disabled] .navbar-inverse .btn-link:hover, fieldset[disabled] .navbar-inverse .btn-link:focus {
  color: #444;
}

.breadcrumb {
  background-color: #f5f5f5;
  border-radius: 0;
  margin-bottom: 22px;
  padding: 8px 15px;
  list-style: none;
}

.breadcrumb > li {
  display: inline-block;
}

.breadcrumb > li + li:before {
  color: #ccc;
  content: "/ ";
  padding: 0 5px;
}

.breadcrumb > .active {
  color: #a5a5a5;
}

.pagination {
  border-radius: 0;
  margin: 22px 0;
  padding-left: 0;
  display: inline-block;
}

.pagination > li {
  display: inline;
}

.pagination > li > a, .pagination > li > span {
  float: left;
  color: #00313a;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
  padding: 6px 12px;
  line-height: 1.42857;
  text-decoration: none;
  position: relative;
}

.pagination > li > a:hover, .pagination > li > a:focus, .pagination > li > span:hover, .pagination > li > span:focus {
  z-index: 2;
  color: #000;
  background-color: #e7e7e7;
  border-color: #ddd;
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #00313a;
  border-color: #00313a;
}

.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
  color: #a5a5a5;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.pagination-lg > li > a, .pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 20px;
  line-height: 1.33333;
}

.pagination-lg > li:first-child > a, .pagination-lg > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-lg > li:last-child > a, .pagination-lg > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-sm > li > a, .pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
}

.pagination-sm > li:first-child > a, .pagination-sm > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-sm > li:last-child > a, .pagination-sm > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pager {
  text-align: center;
  margin: 22px 0;
  padding-left: 0;
  list-style: none;
}

.pager:before, .pager:after {
  content: " ";
  display: table;
}

.pager:after {
  clear: both;
}

.pager li {
  display: inline;
}

.pager li > a, .pager li > span {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 5px 14px;
  display: inline-block;
}

.pager li > a:hover, .pager li > a:focus {
  background-color: #e7e7e7;
  text-decoration: none;
}

.pager .next > a, .pager .next > span {
  float: right;
}

.pager .previous > a, .pager .previous > span {
  float: left;
}

.pager .disabled > a, .pager .disabled > a:hover, .pager .disabled > a:focus, .pager .disabled > span {
  color: #a5a5a5;
  cursor: not-allowed;
  background-color: #fff;
}

.label {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  display: inline;
}

.label:empty {
  display: none;
}

.btn .label {
  position: relative;
  top: -1px;
}

a.label:hover, a.label:focus {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.label-default {
  background-color: #a5a5a5;
}

.label-default[href]:hover, .label-default[href]:focus {
  background-color: #8b8b8b;
}

.label-primary {
  background-color: #00313a;
}

.label-primary[href]:hover, .label-primary[href]:focus {
  background-color: #000607;
}

.label-success {
  background-color: #5cb85c;
}

.label-success[href]:hover, .label-success[href]:focus {
  background-color: #449d44;
}

.label-info {
  background-color: #5bc0de;
}

.label-info[href]:hover, .label-info[href]:focus {
  background-color: #31b0d5;
}

.label-warning {
  background-color: #f0ad4e;
}

.label-warning[href]:hover, .label-warning[href]:focus {
  background-color: #ec971f;
}

.label-danger {
  background-color: #d9534f;
}

.label-danger[href]:hover, .label-danger[href]:focus {
  background-color: #c9302c;
}

.badge {
  min-width: 10px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #a5a5a5;
  border-radius: 10px;
  padding: 3px 7px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.btn-xs .badge, .btn-group-xs > .btn .badge {
  padding: 1px 5px;
  top: 0;
}

.list-group-item.active > .badge, .nav-pills > .active > a > .badge {
  color: #00313a;
  background-color: #fff;
}

.list-group-item > .badge {
  float: right;
}

.list-group-item > .badge + .badge {
  margin-right: 5px;
}

.nav-pills > li > a > .badge {
  margin-left: 3px;
}

a.badge:hover, a.badge:focus {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.jumbotron {
  color: inherit;
  background-color: #e7e7e7;
  margin-bottom: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.jumbotron h1, .jumbotron .h1 {
  color: inherit;
}

.jumbotron p {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 200;
}

.jumbotron > hr {
  border-top-color: #cdcdcd;
}

.container .jumbotron, .container-fluid .jumbotron {
  border-radius: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.jumbotron .container {
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .jumbotron {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .container .jumbotron, .container-fluid .jumbotron {
    padding-left: 60px;
    padding-right: 60px;
  }

  .jumbotron h1, .jumbotron .h1 {
    font-size: 72px;
  }
}

.thumbnail {
  -o-transition: border .2s ease-in-out;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  margin-bottom: 22px;
  padding: 4px;
  line-height: 1.42857;
  transition: border .2s ease-in-out;
  display: block;
}

.thumbnail > img, .thumbnail a > img {
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.thumbnail .caption {
  color: #333;
  padding: 9px;
}

a.thumbnail:hover, a.thumbnail:focus, a.thumbnail.active {
  border-color: #00313a;
}

.alert {
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0;
  margin-bottom: 22px;
  padding: 15px;
}

.alert h4 {
  color: inherit;
  margin-top: 0;
}

.alert .alert-link {
  font-weight: bold;
}

.alert > p, .alert > ul {
  margin-bottom: 0;
}

.alert > p + p {
  margin-top: 5px;
}

.alert-dismissable, .alert-dismissible {
  padding-right: 35px;
}

.alert-dismissable .close, .alert-dismissible .close {
  color: inherit;
  position: relative;
  top: -2px;
  right: -21px;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.alert-warning hr {
  border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-danger hr {
  border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
  color: #843534;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: 22px;
  background-color: #f5f5f5;
  border-radius: 0;
  margin-bottom: 22px;
  overflow: hidden;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  color: #fff;
  text-align: center;
  -o-transition: width .6s ease;
  background-color: #00313a;
  font-size: 14px;
  line-height: 22px;
  transition: width .6s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, .15);
}

.progress-striped .progress-bar, .progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
  background-size: 40px 40px;
}

.progress.active .progress-bar, .progress-bar.active {
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-bar-success {
  background-color: #5cb85c;
}

.progress-striped .progress-bar-success {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
}

.progress-bar-info {
  background-color: #5bc0de;
}

.progress-striped .progress-bar-info {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
}

.progress-bar-warning {
  background-color: #f0ad4e;
}

.progress-striped .progress-bar-warning {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
}

.progress-bar-danger {
  background-color: #d9534f;
}

.progress-striped .progress-bar-danger {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
}

.media {
  margin-top: 15px;
}

.media:first-child {
  margin-top: 0;
}

.media, .media-body {
  zoom: 1;
  overflow: hidden;
}

.media-body {
  width: 10000px;
}

.media-object {
  display: block;
}

.media-object.img-thumbnail {
  max-width: none;
}

.media-right, .media > .pull-right {
  padding-left: 10px;
}

.media-left, .media > .pull-left {
  padding-right: 10px;
}

.media-left, .media-right, .media-body {
  vertical-align: top;
  display: table-cell;
}

.media-middle {
  vertical-align: middle;
}

.media-bottom {
  vertical-align: bottom;
}

.media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.media-list {
  padding-left: 0;
  list-style: none;
}

.list-group {
  margin-bottom: 20px;
  padding-left: 0;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #ddd;
  margin-bottom: -1px;
  padding: 10px 15px;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-item:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 0;
}

.list-group-item.disabled, .list-group-item.disabled:hover, .list-group-item.disabled:focus {
  color: #a5a5a5;
  cursor: not-allowed;
  background-color: #e7e7e7;
}

.list-group-item.disabled .list-group-item-heading, .list-group-item.disabled:hover .list-group-item-heading, .list-group-item.disabled:focus .list-group-item-heading {
  color: inherit;
}

.list-group-item.disabled .list-group-item-text, .list-group-item.disabled:hover .list-group-item-text, .list-group-item.disabled:focus .list-group-item-text {
  color: #a5a5a5;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  z-index: 2;
  color: #fff;
  background-color: #00313a;
  border-color: #00313a;
}

.list-group-item.active .list-group-item-heading, .list-group-item.active .list-group-item-heading > small, .list-group-item.active .list-group-item-heading > .small, .list-group-item.active:hover .list-group-item-heading, .list-group-item.active:hover .list-group-item-heading > small, .list-group-item.active:hover .list-group-item-heading > .small, .list-group-item.active:focus .list-group-item-heading, .list-group-item.active:focus .list-group-item-heading > small, .list-group-item.active:focus .list-group-item-heading > .small {
  color: inherit;
}

.list-group-item.active .list-group-item-text, .list-group-item.active:hover .list-group-item-text, .list-group-item.active:focus .list-group-item-text {
  color: #07d9ff;
}

a.list-group-item, button.list-group-item {
  color: #555;
}

a.list-group-item .list-group-item-heading, button.list-group-item .list-group-item-heading {
  color: #333;
}

a.list-group-item:hover, a.list-group-item:focus, button.list-group-item:hover, button.list-group-item:focus {
  color: #555;
  background-color: #f5f5f5;
  text-decoration: none;
}

button.list-group-item {
  width: 100%;
  text-align: left;
}

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8;
}

a.list-group-item-success, button.list-group-item-success {
  color: #3c763d;
}

a.list-group-item-success .list-group-item-heading, button.list-group-item-success .list-group-item-heading {
  color: inherit;
}

a.list-group-item-success:hover, a.list-group-item-success:focus, button.list-group-item-success:hover, button.list-group-item-success:focus {
  color: #3c763d;
  background-color: #d0e9c6;
}

a.list-group-item-success.active, a.list-group-item-success.active:hover, a.list-group-item-success.active:focus, button.list-group-item-success.active, button.list-group-item-success.active:hover, button.list-group-item-success.active:focus {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d;
}

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7;
}

a.list-group-item-info, button.list-group-item-info {
  color: #31708f;
}

a.list-group-item-info .list-group-item-heading, button.list-group-item-info .list-group-item-heading {
  color: inherit;
}

a.list-group-item-info:hover, a.list-group-item-info:focus, button.list-group-item-info:hover, button.list-group-item-info:focus {
  color: #31708f;
  background-color: #c4e3f3;
}

a.list-group-item-info.active, a.list-group-item-info.active:hover, a.list-group-item-info.active:focus, button.list-group-item-info.active, button.list-group-item-info.active:hover, button.list-group-item-info.active:focus {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}

a.list-group-item-warning, button.list-group-item-warning {
  color: #8a6d3b;
}

a.list-group-item-warning .list-group-item-heading, button.list-group-item-warning .list-group-item-heading {
  color: inherit;
}

a.list-group-item-warning:hover, a.list-group-item-warning:focus, button.list-group-item-warning:hover, button.list-group-item-warning:focus {
  color: #8a6d3b;
  background-color: #faf2cc;
}

a.list-group-item-warning.active, a.list-group-item-warning.active:hover, a.list-group-item-warning.active:focus, button.list-group-item-warning.active, button.list-group-item-warning.active:hover, button.list-group-item-warning.active:focus {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;
}

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede;
}

a.list-group-item-danger, button.list-group-item-danger {
  color: #a94442;
}

a.list-group-item-danger .list-group-item-heading, button.list-group-item-danger .list-group-item-heading {
  color: inherit;
}

a.list-group-item-danger:hover, a.list-group-item-danger:focus, button.list-group-item-danger:hover, button.list-group-item-danger:focus {
  color: #a94442;
  background-color: #ebcccc;
}

a.list-group-item-danger.active, a.list-group-item-danger.active:hover, a.list-group-item-danger.active:focus, button.list-group-item-danger.active, button.list-group-item-danger.active:hover, button.list-group-item-danger.active:focus {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442;
}

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}

.panel {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0;
  margin-bottom: 22px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

.panel-body {
  padding: 15px;
}

.panel-body:before, .panel-body:after {
  content: " ";
  display: table;
}

.panel-body:after {
  clear: both;
}

.panel-heading {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  border-top-left-radius: -1px;
  border-top-right-radius: -1px;
  padding: 10px 15px;
}

.panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}

.panel-title {
  color: inherit;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
}

.panel-title > a, .panel-title > small, .panel-title > .small, .panel-title > small > a, .panel-title > .small > a {
  color: inherit;
}

.panel-footer {
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-left-radius: -1px;
  border-bottom-right-radius: -1px;
  padding: 10px 15px;
}

.panel > .list-group, .panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}

.panel > .list-group .list-group-item, .panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}

.panel > .list-group:first-child .list-group-item:first-child, .panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: -1px;
  border-top-right-radius: -1px;
}

.panel > .list-group:last-child .list-group-item:last-child, .panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-left-radius: -1px;
  border-bottom-right-radius: -1px;
}

.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.panel-heading + .list-group .list-group-item:first-child, .list-group + .panel-footer {
  border-top-width: 0;
}

.panel > .table, .panel > .table-responsive > .table, .panel > .panel-collapse > .table {
  margin-bottom: 0;
}

.panel > .table caption, .panel > .table-responsive > .table caption, .panel > .panel-collapse > .table caption {
  padding-left: 15px;
  padding-right: 15px;
}

.panel > .table:first-child, .panel > .table-responsive:first-child > .table:first-child, .panel > .table:first-child > thead:first-child > tr:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
  border-top-left-radius: -1px;
  border-top-right-radius: -1px;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:first-child, .panel > .table:first-child > thead:first-child > tr:first-child th:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child td:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child th:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
  border-top-left-radius: -1px;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:last-child, .panel > .table:first-child > thead:first-child > tr:first-child th:last-child, .panel > .table:first-child > tbody:first-child > tr:first-child td:last-child, .panel > .table:first-child > tbody:first-child > tr:first-child th:last-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-right-radius: -1px;
}

.panel > .table:last-child, .panel > .table-responsive:last-child > .table:last-child, .panel > .table:last-child > tbody:last-child > tr:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
  border-bottom-left-radius: -1px;
  border-bottom-right-radius: -1px;
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child, .panel > .table:last-child > tbody:last-child > tr:last-child th:first-child, .panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: -1px;
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child, .panel > .table:last-child > tbody:last-child > tr:last-child th:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: -1px;
}

.panel > .panel-body + .table, .panel > .panel-body + .table-responsive, .panel > .table + .panel-body, .panel > .table-responsive + .panel-body {
  border-top: 1px solid #ddd;
}

.panel > .table > tbody:first-child > tr:first-child th, .panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0;
}

.panel > .table-bordered, .panel > .table-responsive > .table-bordered {
  border: 0;
}

.panel > .table-bordered > thead > tr > th:first-child, .panel > .table-bordered > thead > tr > td:first-child, .panel > .table-bordered > tbody > tr > th:first-child, .panel > .table-bordered > tbody > tr > td:first-child, .panel > .table-bordered > tfoot > tr > th:first-child, .panel > .table-bordered > tfoot > tr > td:first-child, .panel > .table-responsive > .table-bordered > thead > tr > th:first-child, .panel > .table-responsive > .table-bordered > thead > tr > td:first-child, .panel > .table-responsive > .table-bordered > tbody > tr > th:first-child, .panel > .table-responsive > .table-bordered > tbody > tr > td:first-child, .panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child, .panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}

.panel > .table-bordered > thead > tr > th:last-child, .panel > .table-bordered > thead > tr > td:last-child, .panel > .table-bordered > tbody > tr > th:last-child, .panel > .table-bordered > tbody > tr > td:last-child, .panel > .table-bordered > tfoot > tr > th:last-child, .panel > .table-bordered > tfoot > tr > td:last-child, .panel > .table-responsive > .table-bordered > thead > tr > th:last-child, .panel > .table-responsive > .table-bordered > thead > tr > td:last-child, .panel > .table-responsive > .table-bordered > tbody > tr > th:last-child, .panel > .table-responsive > .table-bordered > tbody > tr > td:last-child, .panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child, .panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}

.panel > .table-bordered > thead > tr:first-child > td, .panel > .table-bordered > thead > tr:first-child > th, .panel > .table-bordered > tbody > tr:first-child > td, .panel > .table-bordered > tbody > tr:first-child > th, .panel > .table-responsive > .table-bordered > thead > tr:first-child > td, .panel > .table-responsive > .table-bordered > thead > tr:first-child > th, .panel > .table-responsive > .table-bordered > tbody > tr:first-child > td, .panel > .table-responsive > .table-bordered > tbody > tr:first-child > th, .panel > .table-bordered > tbody > tr:last-child > td, .panel > .table-bordered > tbody > tr:last-child > th, .panel > .table-bordered > tfoot > tr:last-child > td, .panel > .table-bordered > tfoot > tr:last-child > th, .panel > .table-responsive > .table-bordered > tbody > tr:last-child > td, .panel > .table-responsive > .table-bordered > tbody > tr:last-child > th, .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td, .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}

.panel > .table-responsive {
  border: 0;
  margin-bottom: 0;
}

.panel-group {
  margin-bottom: 22px;
}

.panel-group .panel {
  border-radius: 0;
  margin-bottom: 0;
}

.panel-group .panel + .panel {
  margin-top: 5px;
}

.panel-group .panel-heading {
  border-bottom: 0;
}

.panel-group .panel-heading + .panel-collapse > .panel-body, .panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid #ddd;
}

.panel-group .panel-footer {
  border-top: 0;
}

.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #ddd;
}

.panel-default {
  border-color: #ddd;
}

.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}

.panel-default > .panel-heading .badge {
  color: #f5f5f5;
  background-color: #333;
}

.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd;
}

.panel-primary {
  border-color: #00313a;
}

.panel-primary > .panel-heading {
  color: #fff;
  background-color: #00313a;
  border-color: #00313a;
}

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #00313a;
}

.panel-primary > .panel-heading .badge {
  color: #00313a;
  background-color: #fff;
}

.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #00313a;
}

.panel-success {
  border-color: #d6e9c6;
}

.panel-success > .panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d6e9c6;
}

.panel-success > .panel-heading .badge {
  color: #dff0d8;
  background-color: #3c763d;
}

.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d6e9c6;
}

.panel-info {
  border-color: #bce8f1;
}

.panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #bce8f1;
}

.panel-info > .panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f;
}

.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #bce8f1;
}

.panel-warning {
  border-color: #faebcc;
}

.panel-warning > .panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #faebcc;
}

.panel-warning > .panel-heading .badge {
  color: #fcf8e3;
  background-color: #8a6d3b;
}

.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #faebcc;
}

.panel-danger {
  border-color: #ebccd1;
}

.panel-danger > .panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ebccd1;
}

.panel-danger > .panel-heading .badge {
  color: #f2dede;
  background-color: #a94442;
}

.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ebccd1;
}

.embed-responsive {
  height: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive-4by3 {
  padding-bottom: 75%;
}

.well {
  min-height: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 0;
  margin-bottom: 20px;
  padding: 19px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}

.well blockquote {
  border-color: rgba(0, 0, 0, .15);
}

.well-lg {
  border-radius: 0;
  padding: 24px;
}

.well-sm {
  border-radius: 0;
  padding: 9px;
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  filter: alpha(opacity= 20);
  opacity: .2;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
}

.close:hover, .close:focus {
  color: #000;
  cursor: pointer;
  filter: alpha(opacity= 50);
  opacity: .5;
  text-decoration: none;
}

button.close {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
}

.modal-open {
  overflow: hidden;
}

.modal {
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  -moz-transition: -moz-transform .3s ease-out;
  transition: transform .3s ease-out;
  transform: translate(0, -25%);
}

.modal.in .modal-dialog {
  transform: translate(0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  width: auto;
  margin: 10px;
  position: relative;
}

.modal-content {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0;
  outline: 0;
  position: relative;
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
}

.modal-backdrop {
  z-index: 1040;
  background-color: #000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-backdrop.fade {
  filter: alpha(opacity= 0);
  opacity: 0;
}

.modal-backdrop.in {
  filter: alpha(opacity= 50);
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #e5e5e5;
  padding: 15px;
}

.modal-header:before, .modal-header:after {
  content: " ";
  display: table;
}

.modal-header:after {
  clear: both;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857;
}

.modal-body {
  padding: 15px;
  position: relative;
}

.modal-footer {
  text-align: right;
  border-top: 1px solid #e5e5e5;
  padding: 15px;
}

.modal-footer:before, .modal-footer:after {
  content: " ";
  display: table;
}

.modal-footer:after {
  clear: both;
}

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }

  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  }

  .modal-sm {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

.tooltip {
  z-index: 1070;
  line-break: auto;
  text-align: left;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  filter: alpha(opacity= 0);
  opacity: 0;
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.tooltip:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.tooltip:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.tooltip.in {
  filter: alpha(opacity= 90);
  opacity: .9;
}

.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}

.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}

.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}

.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}

.tooltip.top .tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #000;
  margin-left: -5px;
  bottom: 0;
  left: 50%;
}

.tooltip.top-left .tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #000;
  margin-bottom: -5px;
  bottom: 0;
  right: 5px;
}

.tooltip.top-right .tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #000;
  margin-bottom: -5px;
  bottom: 0;
  left: 5px;
}

.tooltip.right .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
  margin-top: -5px;
  top: 50%;
  left: 0;
}

.tooltip.left .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
  margin-top: -5px;
  top: 50%;
  right: 0;
}

.tooltip.bottom .tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #000;
  margin-left: -5px;
  top: 0;
  left: 50%;
}

.tooltip.bottom-left .tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #000;
  margin-top: -5px;
  top: 0;
  right: 5px;
}

.tooltip.bottom-right .tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #000;
  margin-top: -5px;
  top: 0;
  left: 5px;
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0;
  padding: 3px 8px;
}

.tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  position: absolute;
}

.popover {
  z-index: 1060;
  max-width: 276px;
  line-break: auto;
  text-align: left;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0;
  padding: 1px;
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  text-decoration: none;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.popover:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.popover:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.popover:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.popover.top {
  margin-top: -10px;
}

.popover.right {
  margin-left: 10px;
}

.popover.bottom {
  margin-top: 10px;
}

.popover.left {
  margin-left: -10px;
}

.popover > .arrow {
  border-width: 11px;
}

.popover > .arrow, .popover > .arrow:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  display: block;
  position: absolute;
}

.popover > .arrow:after {
  content: "";
  border-width: 10px;
}

.popover.top > .arrow {
  border-top-color: rgba(0, 0, 0, .25);
  border-bottom-width: 0;
  margin-left: -11px;
  bottom: -11px;
  left: 50%;
}

.popover.top > .arrow:after {
  content: " ";
  border-top-color: #fff;
  border-bottom-width: 0;
  margin-left: -10px;
  bottom: 1px;
}

.popover.right > .arrow {
  border-left-width: 0;
  border-right-color: rgba(0, 0, 0, .25);
  margin-top: -11px;
  top: 50%;
  left: -11px;
}

.popover.right > .arrow:after {
  content: " ";
  border-left-width: 0;
  border-right-color: #fff;
  bottom: -10px;
  left: 1px;
}

.popover.bottom > .arrow {
  border-top-width: 0;
  border-bottom-color: rgba(0, 0, 0, .25);
  margin-left: -11px;
  top: -11px;
  left: 50%;
}

.popover.bottom > .arrow:after {
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff;
  margin-left: -10px;
  top: 1px;
}

.popover.left > .arrow {
  border-left-color: rgba(0, 0, 0, .25);
  border-right-width: 0;
  margin-top: -11px;
  top: 50%;
  right: -11px;
}

.popover.left > .arrow:after {
  content: " ";
  border-left-color: #fff;
  border-right-width: 0;
  bottom: -10px;
  right: 1px;
}

.popover-title {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: -1px -1px 0 0;
  margin: 0;
  padding: 8px 14px;
  font-size: 16px;
}

.popover-content {
  padding: 9px 14px;
}

.carousel {
  position: relative;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner > .item {
  -o-transition: .6s ease-in-out left;
  transition: left .6s ease-in-out;
  display: none;
  position: relative;
}

.carousel-inner > .item > img, .carousel-inner > .item > a > img {
  max-width: 100%;
  height: auto;
  line-height: 1;
  display: block;
}

@media (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform .6s ease-in-out;
    -o-transition: -o-transform .6s ease-in-out;
    -moz-transition: -moz-transform .6s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: 1000px;
    transition: transform .6s ease-in-out;
  }

  .carousel-inner > .item.next, .carousel-inner > .item.active.right {
    left: 0;
    transform: translate3d(100%, 0, 0);
  }

  .carousel-inner > .item.prev, .carousel-inner > .item.active.left {
    left: 0;
    transform: translate3d(-100%, 0, 0);
  }

  .carousel-inner > .item.next.left, .carousel-inner > .item.prev.right, .carousel-inner > .item.active {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
}

.carousel-inner > .active, .carousel-inner > .next, .carousel-inner > .prev {
  display: block;
}

.carousel-inner > .active {
  left: 0;
}

.carousel-inner > .next, .carousel-inner > .prev {
  width: 100%;
  position: absolute;
  top: 0;
}

.carousel-inner > .next {
  left: 100%;
}

.carousel-inner > .prev {
  left: -100%;
}

.carousel-inner > .next.left, .carousel-inner > .prev.right {
  left: 0;
}

.carousel-inner > .active.left {
  left: -100%;
}

.carousel-inner > .active.right {
  left: 100%;
}

.carousel-control {
  width: 15%;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
  filter: alpha(opacity= 50);
  opacity: .5;
  background-color: rgba(0, 0, 0, 0);
  font-size: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.carousel-control.left {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#80000000", endColorstr= "#00000000", GradientType= 1);
  background-image: linear-gradient(to right, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 100%);
  background-repeat: repeat-x;
}

.carousel-control.right {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00000000", endColorstr= "#80000000", GradientType= 1);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
  background-repeat: repeat-x;
  left: auto;
  right: 0;
}

.carousel-control:hover, .carousel-control:focus {
  color: #fff;
  filter: alpha(opacity= 90);
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control .icon-prev, .carousel-control .icon-next, .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right {
  z-index: 5;
  margin-top: -10px;
  display: inline-block;
  position: absolute;
  top: 50%;
}

.carousel-control .icon-prev, .carousel-control .glyphicon-chevron-left {
  margin-left: -10px;
  left: 50%;
}

.carousel-control .icon-next, .carousel-control .glyphicon-chevron-right {
  margin-right: -10px;
  right: 50%;
}

.carousel-control .icon-prev, .carousel-control .icon-next {
  width: 20px;
  height: 20px;
  font-family: serif;
  line-height: 1;
}

.carousel-control .icon-prev:before {
  content: "‹";
}

.carousel-control .icon-next:before {
  content: "›";
}

.carousel-indicators {
  z-index: 15;
  width: 60%;
  text-align: center;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  position: absolute;
  bottom: 10px;
  left: 50%;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000 \9 ;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  border-radius: 10px;
  margin: 1px;
  display: inline-block;
}

.carousel-indicators .active {
  width: 12px;
  height: 12px;
  background-color: #fff;
  margin: 0;
}

.carousel-caption {
  z-index: 10;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  right: 15%;
}

.carousel-caption .btn {
  text-shadow: none;
}

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-prev, .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px;
  }

  .carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev {
    margin-left: -10px;
  }

  .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next {
    margin-right: -10px;
  }

  .carousel-caption {
    padding-bottom: 30px;
    left: 20%;
    right: 20%;
  }

  .carousel-indicators {
    bottom: 20px;
  }
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.center-block {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0 / 0 a;
  color: rgba(0, 0, 0, 0);
  text-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

@-ms-viewport {
  width: device-width;
}

.visible-xs, .visible-sm, .visible-md, .visible-lg, .visible-xs-block, .visible-xs-inline, .visible-xs-inline-block, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block, .visible-md-block, .visible-md-inline, .visible-md-inline-block, .visible-lg-block, .visible-lg-inline, .visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }

  table.visible-xs {
    display: table !important;
  }

  tr.visible-xs {
    display: table-row !important;
  }

  th.visible-xs, td.visible-xs {
    display: table-cell !important;
  }

  .visible-xs-block {
    display: block !important;
  }

  .visible-xs-inline {
    display: inline !important;
  }

  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }

  table.visible-sm {
    display: table !important;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  th.visible-sm, td.visible-sm {
    display: table-cell !important;
  }

  .visible-sm-block {
    display: block !important;
  }

  .visible-sm-inline {
    display: inline !important;
  }

  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }

  table.visible-md {
    display: table !important;
  }

  tr.visible-md {
    display: table-row !important;
  }

  th.visible-md, td.visible-md {
    display: table-cell !important;
  }

  .visible-md-block {
    display: block !important;
  }

  .visible-md-inline {
    display: inline !important;
  }

  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }

  table.visible-lg {
    display: table !important;
  }

  tr.visible-lg {
    display: table-row !important;
  }

  th.visible-lg, td.visible-lg {
    display: table-cell !important;
  }

  .visible-lg-block {
    display: block !important;
  }

  .visible-lg-inline {
    display: inline !important;
  }

  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }

  table.visible-print {
    display: table !important;
  }

  tr.visible-print {
    display: table-row !important;
  }

  th.visible-print, td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }

  .hidden-print {
    display: none !important;
  }
}

.browserupgrade {
  color: #000;
  background: #a5a5a5;
  margin: .2em 0;
  padding: .2em 0;
}

body {
  min-width: 320px;
  font-weight: 300;
}

.logo-subtext {
  text-transform: uppercase;
  padding-right: 40px;
  font-size: 17px;
}

@media (min-width: 768px) {
  .logo-subtext {
    padding-right: 50px;
    font-size: 24.5px;
  }
}

.logo-img {
  width: 80%;
}

@media (min-width: 768px) {
  .logo-img {
    width: 20%;
  }
}

.page-header {
  border: none;
}

.hero {
  min-height: 40px;
  background: #fff 0 0 / cover;
}

.hero.home {
  min-height: 600px;
  background: #fff;
  position: relative;
}

.hero.home .hero-border {
  mix-blend-mode: multiply;
}

@media (min-width: 992px) {
  .hero.home {
    min-height: 850px;
  }
}

.logo-pos {
  text-align: right;
  padding-right: 20px;
}

@media (max-width: 1199px) {
  .hero {
    display: none;
  }

  .hero.home, .hero.carousel {
    display: block;
  }
}

.carousel {
  height: 200px;
  margin-top: 25px;
}

@media (min-width: 992px) {
  .carousel {
    height: 400px;
  }
}

@media (min-width: 1200px) {
  .carousel {
    height: 500px;
  }
}

.hero-border {
  min-height: 12.5%;
  width: 100%;
  background: #00313a;
  position: absolute;
  top: 0;
}

@media (min-width: 768px) {
  .hero-border {
    min-height: 8%;
  }
}

.hero-bottom {
  min-height: 15%;
  width: 100%;
  position: absolute;
  top: auto;
  bottom: 0;
}

@media (min-width: 768px) {
  .hero-bottom {
    min-height: 10%;
  }
}

.hero-bottom .hero-border {
  height: 15%;
  top: initial;
  position: fixed;
}

.actionicon-container {
  width: 100%;
  height: 10vw;
  max-height: 12vh;
  position: absolute;
  top: 20px;
}

@media (max-width: 767px) {
  .actionicon-container.bottom {
    top: initial;
    bottom: 0;
    left: 0;
  }
}

@media (min-width: 768px) {
  .actionicon-container {
    top: 40px;
  }

  .actionicon-container.bottom {
    top: initial;
    height: 5vw;
    max-height: 10vh;
    bottom: 80px;
  }
}

@media (min-width: 992px) {
  .actionicon-container {
    top: 23px;
  }
}

@media (min-width: 1200px) {
  .actionicon-container {
    top: 20px;
  }
}

.actionicons {
  text-align: center;
  width: 100%;
  height: 200%;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin: 0 auto;
  display: -ms-flexbox;
  display: flex;
}

.actionicons.left {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@media (max-width: 767px) {
  .actionicons.left {
    text-align: left;
  }

  .actionicons img {
    width: 100%;
    margin: 2% 0;
    padding: 0 5px;
  }

  .actionicons img:first-child {
    padding-left: 10px;
  }

  .actionicons img:last-child {
    padding-right: 10px;
  }
}

@media (min-width: 768px) {
  .actionicons {
    width: 90%;
  }

  .actionicons img {
    height: 90%;
    width: auto;
  }
}

@media (min-width: 992px) {
  .actionicons {
    width: 85%;
  }

  .actionicons img {
    height: 80%;
    width: auto;
  }
}

@media (min-width: 1200px) {
  .actionicons {
    width: 83%;
  }

  .actionicons img {
    height: 125%;
    width: auto;
  }
}

@media (min-width: 1400px) {
  .actionicons {
    width: 80%;
  }

  .actionicons img {
    height: 150%;
    width: auto;
  }
}

.navbar-reiss {
  color: #00313a;
  min-height: 38px;
  background: #fff;
  border: none;
  border-bottom: 1px solid #00313a;
  margin: 0;
  font-size: 16px;
}

@media (min-width: 768px) {
  .navbar-reiss {
    min-height: 60px;
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .navbar-reiss {
    min-height: 40px;
  }
}

@media (min-width: 1200px) {
  .navbar-reiss {
    min-height: 50px;
    font-size: 21px;
    line-height: 43px;
  }
}

@media (min-width: 1400px) {
  .navbar-reiss {
    font-size: 24.5px;
  }
}

.navbar-reiss .navbar-collapse {
  padding-left: 0;
  padding-right: 0;
}

.navbar-reiss .navbar-toggle {
  margin: 0;
}

.navbar-reiss.navbar-inverse {
  background: #00313a;
}

.navbar-reiss.navbar-inverse .nav-dots > li > a {
  color: #fff;
}

.navbar-reiss.navbar-inverse .navbar-toggle {
  background: #fff;
  border: none;
}

.navbar-reiss.navbar-inverse .nav-dots > li > a:hover {
  border-bottom: 1px solid #fff;
}

@media (min-width: 768px) {
  .navbar-reiss.navbar-inverse {
    background: none;
    border-bottom: none;
    margin-bottom: 1%;
  }
}

.navbar-brand {
  color: #00313a;
  height: 38px;
  font-weight: bold;
  line-height: 16px;
}

.navbar-brand:focus {
  color: #000;
}

.bordered-nav.navbar-reiss {
  border-bottom: 1px solid #00313a;
}

.nav-dots > li {
  text-align: center;
  text-transform: uppercase;
}

.nav-dots > li > a {
  color: #00313a;
  padding: 0;
}

.nav-dots > li > a:hover, .nav-dots > li > a.active, .nav-dots > li > a:focus {
  background: none;
}

@media (max-width: 768px) {
  .nav-dots > li > a {
    height: 30px;
    border-bottom: 1px solid #003e49;
    margin-bottom: 0;
    line-height: 30px;
  }

  .nav-dots > li.active a {
    font-weight: bold;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .nav-dots > li.active a {
    border-bottom: 1px solid #00313a;
  }
}

@media (min-width: 1200px) {
  .nav-arrow {
    height: 18px;
    width: 18px;
    background: #00313a;
    border-radius: 18px;
    margin-left: -9px;
    position: absolute;
    bottom: -16px;
    left: 50%;
  }
}

.timeline {
  padding: 10px 0;
  list-style: none;
  position: relative;
}

@media (min-width: 768px) {
  .timeline {
    padding: 20px 0;
  }
}

.timeline:before {
  content: " ";
  width: 3px;
  background-color: #e7e7e7;
  margin-left: -1.5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 92%;
}

@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }
}

.timeline > li {
  margin-bottom: 20px;
  position: relative;
}

.timeline > li:before, .timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline .timeline-panel {
  float: left;
  width: 83%;
  border: 1px solid #a5a5a5;
  border-radius: 2px;
  padding: 10px;
  position: relative;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .176);
}

@media (min-width: 768px) {
  .timeline .timeline-panel {
    width: 46%;
    padding: 20px;
  }
}

.timeline .timeline-panel:before {
  content: " ";
  border: 15px solid rgba(0, 0, 0, 0);
  border-left-color: #b4b4b4;
  border-right: 0 solid #b4b4b4;
  display: inline-block;
  position: absolute;
  top: 18px;
  right: -15px;
}

@media (min-width: 768px) {
  .timeline .timeline-panel:before {
    top: 26px;
  }
}

.timeline .timeline-panel:after {
  content: " ";
  border: 15px solid rgba(0, 0, 0, 0);
  border-left-color: #fff;
  border-right: 0 solid #fff;
  display: inline-block;
  position: absolute;
  top: 18px;
  right: -13px;
}

@media (min-width: 768px) {
  .timeline .timeline-panel:after {
    top: 26px;
  }

  .timeline .timeline-inverted .timeline-panel {
    float: right;
  }

  .timeline .timeline-inverted .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  .timeline .timeline-inverted .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 15px;
    left: -13px;
    right: auto;
  }
}

.timeline-badge {
  color: #fff;
  height: 35px;
  text-align: center;
  width: 35px;
  z-index: 10;
  background-color: #555;
  border-radius: 50%;
  margin-left: -25px;
  padding-top: 1px;
  padding-left: 1px;
  font-size: .8em;
  line-height: 35px;
  position: absolute;
  top: 16px;
  left: 94%;
}

@media (min-width: 768px) {
  .timeline-badge {
    height: 50px;
    width: 50px;
    margin-left: -25px;
    padding-top: 2px;
    font-size: 1em;
    line-height: 50px;
    top: 16px;
    left: 50%;
  }
}

.timeline-badge.primary {
  background-color: #00313a;
}

.timeline-badge.success {
  background-color: #5cb85c;
}

.timeline-badge.warning {
  background-color: #f0ad4e;
}

.timeline-badge.danger {
  background-color: #d9534f;
}

.timeline-badge.info {
  background-color: #5bc0de;
}

.timeline-title {
  color: inherit;
  margin-top: 0;
}

.timeline-body > p, .timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

.buildings {
  padding: 10px;
}

@media (min-width: 992px) {
  .buildings {
    justify-content: space-evenly;
    display: -ms-flexbox;
    display: flex;
  }
}

.block-plans {
  color: #fff;
  background: #00313a;
  padding: 10px 0;
  position: relative;
}

.block-plans a {
  color: #d3f8ff;
}

.block-plans h3, .block-plans h5 {
  text-transform: uppercase;
}

.block-plans h3 small, .block-plans h5 small {
  color: #fff;
}

.block-plans:before, .block-plans:after {
  content: "";
  width: 9999px;
  background: #00313a;
  position: absolute;
  top: 0;
  bottom: 0;
}

.block-plans:before {
  right: 100%;
}

.block-plans:after {
  left: 100%;
}

.badgeicon {
  color: #fff;
  height: 35px;
  text-align: center;
  width: 35px;
  z-index: 10;
  background-color: #555;
  border-radius: 50%;
  margin-left: -25px;
  padding-top: 1px;
  padding-left: 1px;
  font-size: .8em;
  line-height: 35px;
  position: absolute;
  top: 16px;
  left: 94%;
}

@media (min-width: 768px) {
  .badgeicon {
    height: 50px;
    width: 50px;
    margin-left: -25px;
    padding-top: 2px;
    font-size: 1em;
    line-height: 50px;
    top: 16px;
    left: 50%;
  }
}

.uppercase {
  text-transform: uppercase;
}

h1 {
  word-break: break-word;
}

#map {
  height: 340px;
  width: 100%;
}

.content {
  text-align: center;
  margin-bottom: 1em;
}

.content h1, .content h2, .content h3, .content h4 {
  text-transform: uppercase;
}

.main {
  padding-bottom: 4rem;
  overflow: hidden;
}

.rich-text > ul {
  text-align: left;
  width: 66%;
  margin-left: 11%;
}

.img-responsive {
  margin: auto;
}

.hero.row {
  margin-right: 0;
}

.block-paragraph > ul {
  text-align: left;
  width: 66%;
  margin-left: 11%;
}

.image-mid {
  float: left;
  width: 50%;
  margin: 0;
  padding-right: 2rem;
}

.image-full {
  width: 100%;
  margin: 0;
}

.caption {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-style: italic;
  line-height: 1.125;
}

.contact-form {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.text-xl {
  font-size: 3rem;
}

.text-uppercase, .initialism {
  text-transform: uppercase;
}

/*# sourceMappingURL=index.9518a408.css.map */
