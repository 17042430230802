$icon-font-path: '../../node_modules/bootstrap-sass/assets/fonts/bootstrap/';

$fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts";

@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

@import "variables";

@import "node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss";

.browserupgrade {
  background: $gray-light;
  color: $gray-base;
  margin: 0.2em 0;
  padding: 0.2em 0;
}

body {
  font-weight: 300;
  min-width: 320px;
}

@import "header";
@import "nav";
@import "timeline";
@import "offers";

.uppercase {
  text-transform: uppercase;
}

h1 {
  word-break: break-word;
}

#map {
  height: 340px;
  width: 100%;
}

.content {
  text-align: center;
  margin-bottom: 1em;

  h1, h2, h3, h4 {
    text-transform: uppercase;
  }
}

.main {
  overflow: hidden;
  padding-bottom: 4rem;
}

.rich-text > ul {
  text-align: left;
  margin-left: 11%;
  width: 66%;
}

.img-responsive {
  margin: auto;
}

.hero.row {
  margin-right: 0;
}

.block-paragraph > ul {
    text-align: left;
    margin-left: 11%;
    width: 66%;
}

.image-mid {
  float: left;
  width: 50%;
  margin: 0;
  padding-right: 2rem;
}

.image-full {
  width: 100%;
  margin: 0;
}

.caption {
  line-height: 1.125;
  font-style: italic;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.contact-form {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.text-xl {
  font-size: 3rem;
}

.text-uppercase {
  text-transform: uppercase;
}
