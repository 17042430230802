.timeline {
  list-style: none;
  padding: 10px 0;
  position: relative;

  @media(min-width: $screen-sm-min) {
    padding: 20px 0;
  }

  &:before {
    background-color: $gray-lighter;
    bottom: 0;
    content: " ";
    left: 92%;
    margin-left: -1.5px;
    position: absolute;
    top: 0;
    width: 3px;

    @media(min-width: $screen-sm-min) {
      left: 50%;
    }
  }

  & > li {
    margin-bottom: 20px;
    position: relative;
  }

  > li:before,
  > li:after {
    content: " ";
    display: table;
  }

  > li:after {
    clear: both;
  }

  .timeline-panel {
    border-radius: 2px;
    border: 1px solid $gray-light;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    float: left;
    padding: 10px;
    position: relative;
    width: 83%;

    @media(min-width: $screen-sm-min) {
      padding: 20px;
      width: 46%;
    }
  }

  $timeline-arrow-width: 15px;
  .timeline-panel:before {
    border-bottom: $timeline-arrow-width solid transparent;
    border-left: $timeline-arrow-width solid darken($gray-lighter, 20%);
    border-right: 0 solid darken($gray-lighter, 20%);
    border-top: $timeline-arrow-width solid transparent;
    content: " ";
    display: inline-block;
    position: absolute;
    right: -$timeline-arrow-width;
    top: 18px;

    @media(min-width: $screen-sm-min) {
      top: 26px;
    }
  }

  .timeline-panel:after {
    border-bottom: $timeline-arrow-width solid transparent;
    border-left: $timeline-arrow-width solid $white;
    border-right: 0 solid $white;
    border-top: $timeline-arrow-width solid transparent;
    content: " ";
    display: inline-block;
    position: absolute;
    right: -$timeline-arrow-width + 2;
    top: 18px;

    @media(min-width: $screen-sm-min) {
      top: 26px;
    }
  }

  @media(min-width: $screen-sm-min) {
    .timeline-inverted .timeline-panel {
      float: right;
    }

    .timeline-inverted .timeline-panel:before {
      border-left-width: 0;
      border-right-width: $timeline-arrow-width;
      left: -$timeline-arrow-width;
      right: auto;
    }

    .timeline-inverted .timeline-panel:after {
      border-left-width: 0;
      border-right-width: $timeline-arrow-width;
      left: -$timeline-arrow-width + 2;
      right: auto;
    }
  }
}

$bagde-size: 35px;

.timeline-badge {
  background-color: $gray;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  color: $white;
  font-size: 0.8em;
  height: $bagde-size;
  left: 94%;
  line-height: $bagde-size;
  margin-left: -25px;
  padding-left: 1px;
  padding-top: 1px;
  position: absolute;
  text-align: center;
  top: 16px;
  width: $bagde-size;
  z-index: 10;

  $bagde-size-md: 50px;

  // @media(min-width: $screen-sm-min) {
  //   left: 95%;
  // }

  @media(min-width: $screen-sm-min) {
    font-size: 1em;
    height: $bagde-size-md;
    left: 50%;
    line-height: $bagde-size-md;
    margin-left: -25px;
    padding-top: 2px;
    top: 16px;
    width: $bagde-size-md;
  }

  &.primary {
    background-color: $brand-primary;
  }

  &.success {
    background-color: $brand-success;
  }

  &.warning {
    background-color: $brand-warning;
  }

  &.danger {
    background-color: $brand-danger;
  }

  &.info {
    background-color: $brand-info;
  }
}

.timeline-title {
  color: inherit;
  margin-top: 0;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}


@media(min-width: $screen-sm-min) {}
