.buildings {
  padding: 10px;

  @media(min-width: $screen-md-min) {
    display: flex;
    justify-content: space-evenly;
  }

}

.block-plans {
  color: $white;
  padding: 10px 0;
  position: relative;
  background: $brand-primary;

  a {
    color: lighten($brand-primary, 80%);
  }

  h3, h5 {
    text-transform: uppercase;

    small {
      color: $white;
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    background: $brand-primary;  /* Match the background */
    top: 0;
    bottom: 0;
    width: 9999px;   /* some huge width */
  }

  &:before {
    right: 100%;
  }
  &:after {
    left: 100%;
  }
}

.badgeicon {
  background-color: $gray;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  color: $white;
  font-size: 0.8em;
  height: $bagde-size;
  left: 94%;
  line-height: $bagde-size;
  margin-left: -25px;
  padding-left: 1px;
  padding-top: 1px;
  position: absolute;
  text-align: center;
  top: 16px;
  width: $bagde-size;
  z-index: 10;

  $bagde-size-md: 50px;

  // @media(min-width: $screen-sm-min) {
  //   left: 95%;
  // }

  @media(min-width: $screen-sm-min) {
    font-size: 1em;
    height: $bagde-size-md;
    left: 50%;
    line-height: $bagde-size-md;
    margin-left: -25px;
    padding-top: 2px;
    top: 16px;
    width: $bagde-size-md;
  }
}
